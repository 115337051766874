#admin-sticky-message {
  display: table;
  position: absolute;
  top: -15px;
  margin: 0 0 0 -250px;
  border-radius: 0 0 3px 3px;
  left: 50%;
  background: #efefef;
  color: #222;
  padding: 4px 9px 5px 10px;
  width: auto;
  z-index: 9999;
}

.admin-sticky-inner {
  position: relative;
}

.admin-sticky-inner-text {
  float: left;
  padding: 5px 0 0 0;
  position: relative;
}

em.highlight {
  font-weight: bold;
  color: #000;
}

.admin-sticky-inner ul {
  float: left;
  margin: 0;
  padding: 0;
}

.admin-sticky-inner ul li {
  margin: 0;
  list-style: none;
}

.admin-sticky-inner a {
  float: right;
  margin: 0 0 0 25px;
  color: #fff;
  background-color: #f74866;
  display: block;
  border: 0;
  border-radius: 2px;
  padding: 6px 10px 5px 10px;
  font-size: 13px;
  font-weight: normal;
  line-height: 14px;
  position: relative;
  z-index: 999;
}
.DashboardPlaylistsCard {
	overflow: auto;
	height: calc(100vh - 205px);
	margin-top: 7px;
}
.DashboardPlaylistsCard li {
	min-height: 90px;
	margin: 0 0 10px 0;
	padding: 10px 0 0 10px;
	display: block;
	background: #333;
	border-radius: 0;
	color: #fff;
}
.DashboardPlaylistsCard li:first-child,
.DashboardPlaylistsCard li:last-child {
  border-radius: 0;
}
.DashboardPlaylistsCard li a.image {
  position: absolute;
  margin: 0 10px 0 0;
  cursor: pointer;
  left: 10px;
}
.DashboardPlaylistsCard li img {
    float: left;
    max-width: 100px;
    display: table;
    position: relative;
    max-height: 80px;
}
.previewicon {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  display: none;
}
.previewicon svg {
  position: absolute;
  top: 45%;
  left: 42%;
  color: #fff;
}
a.image:hover .previewicon {
  display: block;
}
.DashboardPlaylistsCard li .title {
  float: left;
  width: calc(100% - 110px);
  margin-left: 33%;
}
.DashboardPlaylistsCard li .updated {
  font-weight: 100;
  color: #999;
  margin: 5px 0 5px 33%;
  float: left;
  width: calc(100% - 110px);
}
.DashboardPlaylistsCard li .actions{
  border-top: 1px solid rgba(255,255,255,.1);
  margin: 5px 0 0 33%;
  float: left;
  width: calc(100% - 110px);
  text-align: center;
}

.DashboardPlaylistsCard li .actions .addScreens {
  border-bottom: 0;
  padding: 10px 5px 12px;
  width: 55%;
  float: left;
  height: 37px;
  border-right: 1px solid rgba(255,255,255,.1);
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DashboardPlaylistsCard li .actions .addScreens svg {
  font-size: 17px;
  margin: 0 3px 3px 0;
}
.DashboardPlaylistsCard li .actions .edit {
  border-bottom: 0;
  padding: 10px 5px 12px;
  width: 45%;
  float: left;
  height: 37px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.DashboardPlaylistsCard li .actions .edit svg {
  font-size: 16px;
  margin: 0 7px 5px 0;
}
.DashboardPlaylistsCard li a:hover {
  background: #222;
  color: #fff;
}

  @media only screen and (min-width: 500px) and (max-width: 1000px){
  .DashboardPlaylistsCard li .title {
    width: calc(100% - 210px);
  }
  .DashboardPlaylistsCard li .updated, .DashboardPlaylistsCard li .actions {
    width: calc(100% - 210px);
  }
}
@media only screen and (min-width: 1200px){
  .DashboardPlaylistsCard li .title {
    width: calc(100% - 120px);
  }
  .DashboardPlaylistsCard li .updated, .DashboardPlaylistsCard li .actions {
    width: calc(100% - 120px);
  }
}

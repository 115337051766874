#edit-account {
    margin: 0 auto 70px auto; width: 350px;
}

#edit-account label {
    font-weight: 100;
    margin-bottom: 0;
}

#edit-account input, #edit-account .form-control[disabled] {
    border: none;
    border-bottom: 1px solid #999;
    background: transparent;
    width: 100%;
    color: #999;
    padding-top: 5px;
    box-shadow: none;
    padding-left: 0; border-radius: 0; font-size: 13px;
}

#edit-account .form-item.form-group {
  width: 100%;
  float: none;
}

#edit-account #user-profile-form{ margin:0 auto 70px auto; }

#edit-account #edit-actions #edit-cancel{ padding: 11px 12px; }

#edit-account textarea {
    border: 0;
    border-bottom: 1px solid #999;
    max-height: 30px;
    border-radius: 0; background: transparent;
}

#edit-account .form-item {
    margin: 0 0 20px 0;
}

#edit-account select {
    padding: 7px 7px 7px 7px;
    margin: 10px 0 0 0;
    font-size: 14px;
    background: #ccc;
    color: #000;
    cursor: pointer;
    -webkit-appearance: auto;
}

#edit-actions #edit-cancel {
    padding: 10px 12px;
    background: #222;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
}

#edit-account #edit-actions {
    width: 100%;
    margin: 0 auto;
    text-align: left;
}
.profile-success-alert {
    position: fixed;
    top: 30%;
    left: 38%;
}

.profile-success-alert .fade.alert.alert-success.show {
    padding-left: 100px;
    padding-right: 100px;
}

.form-item.form-item-field-country-language.form-type-select {
    padding-top: 10px;
    display: block;
}

textarea#street-address-1 {
    padding-left: 0;
	font-size:13px;
}

/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Playlists.module.css
File Description: Styles playlists page of CMS portal
Copyright 2021 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
/* PLAYLIST START  */
.default_playlist_bm {
  background: url(../../Assets/manager-default-playlist-icon.png) no-repeat right top !important;
  border: 1px solid #cba052 !important;
}

.playlist-radio-chk {
  content: "\f058";
  color: #f74866 !important;
}

#playlistModal h3 {
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  float: left;
  margin: 5px 0 10px 0;
  line-height: 24px
}

.modal.show .modal-dialog {
  width: 56%;
}

#playlistModal .modal-header {
  padding: 10px 15px 5px 15px;
  border-bottom: 1px solid #e5e5e5;
}

#playlistModal .start-button {
  margin: 0 auto;
  text-align: center;
}

#playlistModal #LetStart {
  border: 0;
  border-radius: 2px;
  padding: 10px 12px 10px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  margin-right: 5px;
}

#playlistModal .grayBG {
  background-color: #e0e0e0;
  padding: 10px 20px 20px 20px;
  margin: 0 10px 15px 10px;
  min-height: 32px;
  font-weight: 100;
  width: 97%;
}

.no-pad {
  margin-top: 10px;
}

#playlistModal input#floorInfoName {
  background: #eee;
  border-radius: 2px;
  padding: 8px !important;
  color: #333;
  border: 1px solid #a1a1a1;
  margin: 0 0 15px 0;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  float: right;
}

.pagination>.active>a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #f74866;
}

.pagination>li>a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: #333;
  border-color: #47ccde;
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: #47ccde
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset
}

.container.default-present {
  padding-bottom: 35px;
}

.btn-success i {
  margin-right: 10px;
}

.playlist-radio-chk .radio-default:before {
  content: "\f058";
  color: #f74866;

}

.react-confirm-alert-button-group button {
  color: #fff;
  background-color: #f74866;
}


h3.Playlistsubtitle {
  background-color: #222;
  padding: 10px;
  color: #ccc;
  font-size: 13px;
  display: table;
  clear: both;
}

.playlists-card {
  padding: 10px 15px 20px 15px;
  background: #222;
  margin-bottom: 10px;
  height: 110px;
}

.playlists-default {
  border: 1px solid #f74866;
  background: url(../../Assets/default-playlist-icon.png) no-repeat right top;
  background-color: #222;

}

.playlists-card:before,
.playlists-card:after {
  content: " ";
  display: table;
}

.playlists-card:after {
  clear: both;
}

.playlists-card .playlists-img,
.playlists-card .playlists-details {
  float: left;
}

.playlists-card .playlists-img {
  position: relative;
  display: block;
  width: 20%;
}

.playlists-card .playlists-img img {
  width: 100px;
  display: block;
  max-width: 100px;
  height: 70px;
  box-shadow: 5px 10px #919191, 10px 20px #4e4e4e;
}

.container.playlist-container .row {
  height: calc(100vh - 314px);
}

.playlists-card .playlists-img i {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -12.5px;
  margin-left: -12.5px;
  color: #FFF;
  font-size: 25px;
  zoom: 1;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  -moz-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.playlists-card .playlists-img:hover i {
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  zoom: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}

.playlists-card .playlists-details {
  width: 80%;
}

.playlists-card .playlists-details h4 {
  font-size: 14px;
  color: #999;
}

.playlists-card .playlists-details p {
  font-size: 11px;
  line-height: 13px;
  color: #999;
}

.playlist-content-hold {
  width: 70%;
}

.playlist-icon-hold {
  width: 30%;
}


.playlists-card .playlists-details .info-list {
  margin-left: 18px;
}

.playlists-card .playlists-details .edit-list {
  margin-left: 24px;
}

.playlists-card .playlists-details .info-list-bx {
  padding: 0px;
  width: 80px;
  margin: 8px;
  height: 70px;
  background-color: #333;
  text-align: center;
  vertical-align: middle;
}

.playlists-card .playlists-details .info-list-bx:hover {
  background-color: #111;
}

.playlists-card .playlists-details .info-list-bx a i {
  color: #999;
  font-size: 24px;
  display: block;
  margin: 0 auto 2px auto;
  text-align: center;
  margin-top: 15px;
}

.playlists-card .playlists-details .info-list-bx a i p {
  font-size: 9px;
}

.playlists-card .playlists-details .info-list-settings a i {
  border-radius: 3px;
  background-color: #333;
  margin: 20px 10px 0px 50px;
  padding: 12px 20px;
}

.playlists-card .playlists-details .info-list-settings a i:hover {
  background-color: #444;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.float-right {
  float: right;
}


.form-control:focus {
  color: #999;
  background-color: #222;
  border-color: #274b69;
  outline: 0;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  border-radius: 2px;
}

*:focus {
  outline: none;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999;
  background-color: #333;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 2px;
  border: 1px solid #222;

}

.btn-default {
  color: #fff;
  background-color: #555;
  border: 0;
  border-radius: 2px;
  padding: 10px 12px 10px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  margin: 0 0px 0 5px;
}

.btn-default.disabled,
.btn-default:disabled {
  background-color: #999;
  color: #ccc;
}

.btn-default:hover,
.btn-default:active,
.btn-default:focus,
.btn-default:visited,
.btn-default:target {
  color: #fff;
  background-color: #555;
  border: none;
  box-shadow: none;
}

.btn-default:hover {
  background-color: #444;
  color: #fff;
}

.btn-default i {
  margin-right: 10px;
}

.v-align {
  margin-top: 0;
}

.pagination a {
  margin: 5px;
}

.playlist-page .subtitle {
  margin-bottom: 10px;
}

.playlist-main-block {
  width: 50%;
  float: left;
  padding: 0 5px;
}

.playlist-container .row {
  margin-right: calc(var(--bs-gutter-x) * -.25);
  margin-left: calc(var(--bs-gutter-x) * -.25);
}

.container.playlist-container {
  margin: 0 auto;
}

.search-bar .d-flex_.p-2.text-right.flex.justify-content-center {
  padding-top: 0 !important;
  padding-right: 5px !important;
}

.playlist-inside {
  height: calc(100vh - 275px);
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  display: block;
}

.container.block-container {
  height: calc(100vh - 275px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
}

.playlist-main-block:nth-child(2n) {
  margin: 0;
}

ul.pagination li a {
  border: 0;
  margin: 0;
}

.playlist-page ul.pagination {
  padding: 0;
  margin: 0;
  float: right;
}

.default-present .container.block-container {
  height: calc(100vh - 345px);
}

.playlists-card.playlists-default,
.playlists-card.default_playlist_bm {
  width: 97.5%;
  margin: 0 0 10px 8px;
}

.img-content-block {
  float: left;
  width: 48%;
  margin-top: 10px;
}

div#img-content-block {
  background: #1f1f1f;
  padding: 10px;
}

.card-body {
  padding: 5px 0 15px 0;
}

.trm {
  margin: 0 0 10px 0;
}

#playlistModal #react-tabs-1,
#playlistModal #react-tabs-1 ul,
#playlistModal #react-tabs-1 ul li div {
  background: #fff !important;
}



#playlistModal li.react-tabs__tab {
  width: 49.5%;
  float: left;
  display: block;
  color: #fff;
  background: #333 !important;
}

#playlistModal li.react-tabs__tab.react-tabs__tab--selected div:before {
  content: '\f077';
  font-family: "FontAwesome", sans-serif;
}

#playlistModal li.react-tabs__tab div:before {
  margin: 0 7px 0 0;
  color: #ff7351;
  font-weight: 100;
  content: '\f078';
  font-family: "FontAwesome", sans-serif;
}

.user-library-tab .react-tabs__tab-panel,
.user-library-tab .react-tabs__tab-panel ul li div {
  background: #fff !important;
  color: #333 !important;
}

#playlistModal li.react-tabs__tab.react-tabs__tab--selected {
  background: #fff !important;
  color: #333 !important;
}


#playlistModal #react-tabs-0 {
  margin: 0 4px 0 0 !important;
}

.info-list-bx a.disabled:hover {
  cursor: not-allowed;
}

.playlists-card .playlists-details .info-list-bx a.disabled i {
  color: #666;
}

.playlists-card .playlists-details .info-list-bx a.disabled p {
  color: #666;
}

.load-more-playlists .list-count {
    position: relative;
    float: right;
    top: -26px;
}

form.playlist-search-top {
    float: left;
    width: 100%;
    display: table;
}

.playlist-search-top input.form-control {
    display: block;
    width: 250px;
    float: left;
    height: 35px;
    border: 0;
    margin-right: 10px;
}

.col.play-search-nm {
	padding-right: 5px;
    margin-top: 25px;
}

.inner-scroll-active .block-container {
    overflow-y: auto;
    max-height: calc(100vh - 325px);
    overflow-x: hidden;
}

.justify-content-end.load-more-end {
    position: relative;
}


#edit-submit-playlist:active,
#edit-submit-playlist:focus,
#edit-submit-playlist:visited,
#edit-submit-playlist:target {
  color: #fff;
  background-color: #f74866;
  border: none;
  box-shadow: none;
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation : landscape) {
  .playlist-main-block {
    width: 49%;
  }

  .playlist-content-hold {
    width: 70%;
  }

  .playlist-icon-hold {
    width: 30%;
  }

  .playlists-card .playlists-details .info-list {
    margin-left: 35px;
    float: left;
  }

  .playlists-card .playlists-details .info-list-settings {
    width: 60px;
    height: 45px;
    border-radius: 3px;
    background-color: #333;
    margin: 20px 0px 0px 30px;
    padding: 0px 0px 0px 12px;
  }
}

/* PLAYLIST END  */

button.btn-load-more-playlist {
    background: none;
    border: 0;
    font-size: 16px;
    font-weight: bold;
    margin: 0 auto;
    display: table;
    text-align: center;
    padding: 8px 0 0 0;
    color: #f74866;
}

.playlist-footer span.list-count {
    position: relative;
    top: -15px;
    right: 0;
    color: #999;
    padding: 0;
    float: right;
}

span.loading-text {
    text-align: center;
    margin: 10px auto 0 auto;
    display: table; color:#ccc;
}

/* playlist preview type - Full content */

.previewContent .full-preview-content {
  margin: 10px 0 0 15px;
  height: 480px;
  width: 460px;
  overflow: hidden;
  float: left;
}

.full-preview-content img.d-block.w-100   {
  margin: 0 auto;
  overflow: hidden;
  float: initial;
  max-width: 100%;
  max-height: 100%;
}

.full-preview-content.previewContent {
  height: 430px !important;
  width: 680px !important;
  margin: 0;
}
.pl-preview .previewContent .carousel.carousel-slider
{
  height: auto !important;
}

.full-preview-content .carousel .control-dots
{
  bottom: 15%;
  left: 0%;
}

.full-preview-content .carousel.carousel-slider .control-arrow
{
  top: 32% !important;
}

.full-preview-content .carousel .slider-wrapper
{
  width: 100%;
  height: 100% !important;
  margin-top: 0;
  display: table-cell;
  position: relative;
  vertical-align: middle;
}
 .full-preview-content.previewText {
 display:none;
}
.full-preview-content.previewBanner {
 display:none;
}
.page-live-content th {
    border: 1px solid #000;
}

.page-live-content thead {
    background-color: #333;
    color: #ccc;
    font-weight: 800;
}

.page-live-content thead tr {
    padding: 5px 8px;
    background: #333;
    border: 1px solid #000;
}

.page-live-content tr {
    padding: 5px 8px;
    background: #474747;
    border: 1px solid #000;
}

.page-live-content .table-responsive {
    overflow: scroll;
    overflow: auto;
    height: calc(100vh - 120px);
    display: block;
}



.create-live-content .form-group {
    display: table;
    margin: 0 0 10px 0;
    font-weight: 100;
    width: 100%;
}

.create-live-content label {
    font-weight: 100;
    padding: 0 0 3px 0;
}

.create-live-content select.form-control:not([size]):not([multiple]) {
    height: calc(2rem + 2px);
}

.create-live-content #custom-file-upload {
    min-height: 32px;
    background: #222;
    border-radius: 2px;
    font-weight: 100;
    padding: 8px;
    border: 0;
    color: #ccc;
    margin: 0;
}

.create-live-content input[type=text],
.create-live-content .form-control.form-file {
    min-height: 32px;
    background: #222;
    border-radius: 2px;
    font-weight: 100;
    padding: 8px;
    border: 0;
    color: #ccc;
    margin: 0;
}

.create-live-content .form-control {
    background: #222;
    border: 0;
    color: #fff;
    border-radius: 2px;
    line-height: inherit;
    font-size: 13px;
    padding: 6px 8px;
}

.create-live-content select * {
    color: #fff;
    border: 0;
}

.create-live-content .form-item-title {
    background: transparent;
    padding: 0;
}

.create-live-content .form-item {
    margin-bottom: 20px;
    width: 99%;
}

.create-live-content input[type=text]:focus {
    background: #333;
    outline: 0;
}

.go-to {
    margin: 15px 0;
    display: table;
}

.create-live-content #edit-actions {
    text-align: center;
    display: table;
    float: left;
    height: 110px;
    width: auto;
}

#edit-actions #edit-cancel {
    padding: 10px 12px;
    background: #222;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
}

.assign-user-widget {
    background: #333;
    padding: 20px;
    display: table;
    width: 100%;
    margin: 0 0 20px 0;
}

.left-side,
.right-side {
    float: left;
    background: #222;
    padding: 9px;
    overflow-y: scroll;
    height: 167px;
    width: 35%;
}

.left-side li,
.right-side li {
    list-style: none;
}

.left-side ul,
.right-side ul {
    margin: 0;
    padding: 0;
}

.middle {
    float: left;
    display: table;
    width: 20%;
}

/* Widget multiselect user css */
.improvedselect {
    background: #333;
    border: 0;
}

.improvedselect .improvedselect ul.improvedselect_sel,
.improvedselect ul.improvedselect_all,
.improvedselect ul.improvedselect_sel {
    background: #222;
    border: 0;
}

.improvedselect_control span {
    background: #666;
}



input.improvedselect_filter {
    background: #222;
}

.improvedselect input.improvedselect_filter {
    border: 1px solid #444;
    display: table;
    width: 30%;
    margin: 0 0 5px 0;
}

.improvedselect-text-wrapper {
    width: auto;
}

.improvedselect .improvedselect-text-wrapper {
    width: 40%;
    border: 0;
}

.improvedselect input.improvedselect_filter:focus {
    border: 1px solid #555;
}

.improvedselect li:hover {
    background: #444;
}

.improvedselect li.selected {
    background: #444;
}

.improvedselect li {
    margin: 1px 0 !important;
    padding: 3px !important;
}

.improvedselect .add_all,
.improvedselect .del_all,
.improvedselect .del,
.improvedselect .add {
    display: block;
    border: 1px solid gray;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
    background: #666;
    font-size: 20px;
    width: 50px;
    margin-left: auto;
    margin-right: auto;
}

.livecontentmap {
    display: table;
    width: 100%;
}

#mapDiv {
    height: 500px;
}

.preview-img-btn .btn-preview {
    margin: 5px 0 0 10px;
    color: #ccc;
    color: #000;
    border: none;
    font-size: 14px;
    cursor: pointer;
    padding: 5px 7px;
    text-align: center;
    opacity: 1;
    border-radius: 0px !important;
    margin-right: 10px;
}

.btn-preview-ip {
    margin: 10px 0px 0px 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.dlt-popup {
    height: 20vh;
}

.dlt-popup .popup-content {
    height: 16vh;
    margin: 0;
}


.create-live-content {
    overflow: auto;
    height: calc(100vh - 120px);
    display: inline-block;
    width: 100%;
}
.themes_form .improvedselect input[type=text] {
    min-height: 32px;
    background: #222;
    border-radius: 2px;
    font-weight: 100;
    color: #ccc;
}
/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Playlist.css
File Description: Styles playlists page of CMS portal

Copyright 2021 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination>.active>a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}

.pagination>li>a {
  border: 1px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}

.pagination>li>a,
.pagination>li>span {
  color: #47ccde
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset
}

div#edit-userrole {
  float: left;
  margin: 7px 0 0 30px;
}

/* Preview */
.previewContainer {
  background-image: url(../Assets/default-theme-bg.jpg);
  background-size: cover;
  width: 680px;
  height: 430px;
  position: relative;
}

.previewContainer .carousel .slider-wrapper,
.PlaylistsPreview .carousel .slider-wrapper {
  width: 100%;
  background: none;
}

.previewContent .carousel,
.previewBanner .carousel {
  display: none;
}

.previewContent .carousel.carousel-slider,
.previewBanner .carousel.carousel-slider {
  display: block;
}

.previewContent .carousel .control-dots {
  top: 205px;
  left: 0%;
}

.previewBanner .carousel .control-dots {
  top: 95px;
  left: 0%;
}

.previewContent .carousel .control-dots .dot.selected,
.previewBanner .carousel .control-dots .dot.selected {
  background-color: #fff;
}

.previewContent {
  margin: 10px 0 0 15px;
  height: 235px;
  width: 460px;
  overflow: hidden;
  float: left;
}

.previewContent .control-arrow {
  top: -165px !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}

.previewText {
  width: 235px;
  position: relative;
  float: left;
  margin: 40px 0 0;
  padding: 5px 0px;
  height: 125px;
  text-align: center;
  color: #fff;
  word-break: break-word;
}

.previewBanner {
  position: relative;
  margin: 40px 0 0 10px;
  height: 125px;
  width: 235px;
  overflow: hidden;
  float: left;
  text-align: center;
}

.previewText.onlyBannerText {
  width: calc(100% - 175px);
}

.previewBanner.onlyBannerImg {
  margin: 0 auto;
  float: none;
  display: block;
  height: 160px;
  padding: 27px 0;
}

.previewContent.onlyContentImg {
  margin: 90px 0 0 15px;
}

.previewBanner .control-arrow {
  top: -270px !important;
}

.previewBanner .carousel.carousel-slider .control-arrow:before,
.previewBanner .carousel .control-arrow:before {
  font-family: "FontAwesome", sans-serif;
  font-size: 50px;
  font-weight: 400;
  color: #ccc;
}

.forevervalidationmodal .modalClose:before,
.playlistPreview.modalClose:before {
  content: "\f00d";
  font-family: "FontAwesome", sans-serif;
  margin-right: 0;
  font-size: 18px;
  font-weight: 100;
  position: relative;
  border-radius: 50px;
  width: 30px;
  background: #d62d51;
  display: block;
  text-align: center;
  color: #fff;
  height: 30px;
  line-height: 30px;
}

.forevervalidationmodal .modalClose svg,
.playlistPreview.modalClose svg {
  display: none;
}

.previewContent img.d-block.w-100 {
  display: block !important;
  max-width: 425px;
  max-height: 230px;
  width: auto !important;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
}

.previewBanner img.d-block.w-100 {
  display: block !important;
  max-width: 200px;
  max-height: 125px;
  width: auto !important;
  height: auto;
  object-fit: cover;
  margin: 0 auto;
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {
  .playlist-main-block {
    width: 96% !important;
  }

  .playlists-card .playlists-details .info-list-settings a i {
    margin: 20px 0px 0px 20px !important;
  }

  .playlists-card .playlists-details .info-list-bx {
    width: 86px !important;
    margin: 3px !important;
    height: 77px !important;
  }

  .PlaylistsPreview_previewTypeImage__3H-dD {
    height: 105px !important;
    width: 155px !important;
  }

  .previewContent {
    margin: 10px 0 0 10px !important;
    height: 235px !important;
    width: 93% !important;
  }

  .previewBanner.onlyBannerImg {
    height: 160px !important;
    padding: 27px 0 !important;
  }

  .play-btn-dc {
    position: absolute;
    top: 62px;
    right: 30px;
  }

  .play-search-nm {
    display: flex;
  }

  .page-media .view-content ul li {
    margin: 6px 10px 5px 0;
  }

  .page-media li.views-row {
    width: 100%;
  }

  .views-exposed-widget {
    width: 30% !important;
  }

  .mt-5-xs {
    margin-top: 3rem !important;
  }

  .playlist-searchblock-xs {
    flex-direction: column-reverse;
  }

}

.playlist-scroll {
  overflow-y: auto;
  height: calc(100vh - 200px);
  overflow-x: hidden;
}

.carousel .control-prev.control-arrow {
  left: -10px;
}

.carousel .control-next.control-arrow {
  right: -10px;
}

.playlist-scroll .pagination {
  margin: 10px auto 15px;
}

.previewContent .slider li,
.previewBanner .slider li {
  display: inline;
}

.inner-banner-text {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  justify-content: space-around;
  flex-direction: column-reverse;
}

#dc-preview-banner-text {
  height: unset !important;
}


.pl-preview .carousel.carousel-slider .control-arrow {
  top: 80px !important;
  height: fit-content;
}

.pl-preview.modal.show .modal-dialog {
  width: auto;
}

.pl-preview .previewBanner .carousel.carousel-slider,
.pl-preview .previewContent .carousel.carousel-slider {
  height: 240px !important;
}

.pl-preview .previewBanner .carousel.carousel-slider .control-arrow {
  top: 30px !important;
  height: fit-content;
}

.NunitoFont{  
  		font-family:"NunitoSans"; 
}
.inner-banner-text.fontpreview {
  line-height: 30px;
  white-space: inherit;
}
.inner-banner-text div {
    white-space: initial !important;
}
.previewText.onlyBannerText.japan-text {
  margin-top: 0;
  height: 190px;
  transform: scaleY(0.9);
}
 /*full content device preview*/
.full-preview-content .slide div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 430px;
  width: 680px;
}

.full-preview-content .carousel .control-dots {
    bottom: 10% !important;
    left: 0;
    top: auto !important;
}

.full-content .previewContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 430px;
  width: auto;
  padding: 0;
  margin: 0 auto;
}
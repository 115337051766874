.add-theme-form {
  padding-top: 10px;
}

.element_label {
  width: 100%;
  margin-bottom: 0.5%;
  font-weight: lighter;
}

.element_input {
  width: 100%;
  background-color: #222;
  border-radius: 2px;
  min-height: 32px;
  border: 0;
  color: #ccc;
  font-weight: 100;
}

.element_input:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.theme_padding_div {
  padding-bottom: 20px;
}

.actions_button {
  border-color: #d62d51;
  color: #fff;
  background-color: #f74866;
  border: 0;
  border-radius: 2px;
  padding: 10px 12px 10px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  cursor: pointer;
}

.add-theme-form .help-block {
  margin-top: 5px;
  margin-bottom: 1px;
}

.more-information {
  margin: 2px;
  cursor: default;
}

.help-block a {
  cursor: pointer;
}

.hide-instructions {
  display: none;
}

.error-class {
  color: red;
}

.theme-list-wrapper {
  width: 80vw;
}

div.search-box {
  background: #333;
  padding: 10px 15px 20px 15px;
  border-bottom: 1px solid #000;
}

input.theme-search-box {
  background: #222;
}

div.search-input-box {
  width: 20%;
  float: left;
}

.tl-table {
  width: 100%;
}

.tl-search-buttion {
  margin-left: 10px;
}

.tl-table-head-row {
  background: #333;
}

.tl-table-heading {
  border: 1px solid #000 !important;
  border-top: 0 !important;
}

.tl-table-body-row {
  background: #474747 !important;
  color: #ccc;
}

.tl-table-data {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333;
  font-weight: 100;
}

.tl-table-wrapper {
  height: calc(100vh - 230px);
  overflow: auto;
}

.edit-form .theme_padding_div {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.edit-form .actions_div {
  margin-top: 10px;
}

.edit-theme-wrapper {
  margin-left: 18px;
}

.add-theme-form #upload_field {
  display: none;
}

.add-theme-form div.element_input button {
  margin: 5px;
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {
  .theme-list-wrapper {
    width: 83vw;
    overflow: auto;
  }
}
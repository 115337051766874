/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: index.css
File Description: index file

Copyright 2021 - 2023 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
a {
  text-decoration: none;
}

body {
  font-family: 'HelveticaNeueRegular', sans-serif;
  background-color: #000;
  width: 100%;
  font-size: 13px;
  font-weight: 100;
  line-height: normal;
  color: #fff;
  /*This color  is hide due to its overiding ideal session time out popup*/
  position: relative;
  margin: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  box-shadow: 0 0 0 30px black inset !important;
  -webkit-text-fill-color: #999;
  -webkit-animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: autofill;
}

/* Remove outline for non-keyboard :focus */
*:focus:not(:focus-visible) {
  outline: none;
}

/* Optional: Customize :focus-visible */
:focus-visible {
  outline-color: blue;
}

.container {
  padding: 0;
  width: 1180px;
  margin: 15px auto 0;
}

.user-redirect-pages {
  color: #fff;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9998;
  transition: all 0.5s ease-in-out;
}

#sidebar {
  width: 270px;
  position: fixed;
  top: 0;
  left: -215px;
  height: 100vh;
  z-index: 9999;
  background: #1f1f1f;
  color: #fff;
  transition: all 0.3s;
  box-shadow: 3px 3px 3px rgb(0 0 0 / 20%);
}

#sidebar.active {
  left: 0;
}

.dropdown-menu {
  position: relative !important;
  background: transparent;
  margin: 0px 0 0 18px;
  transform: none !important;
}

.btn-info:focus {
  box-shadow: none;
}

.show {
  display: block !important;
}

.btn-success {
  color: #fff;
  background-color: #f74866;
  border: 0;
  border-radius: 2px;
  padding: 10px 12px 10px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #999;
  color: #ccc;
}

.btn-success:hover,
.btn-success:active,
.btn-success:focus,
.btn-success:visited,
.btn-success:target {
  color: #fff;
  background-color: #f74866;
  border: none;
  box-shadow: none;
}

sup {
  font-size: 12px;
}

/* Header section */
.headerSection {
  margin: 0 0 5px;
}

h1.pageTitle {
  font-size: 18px;
  margin: 0 0 5px 0;
  display: table;
  line-height: 1.1;
}

.head-action-button {
  float: right;
}

.subtitle {
  margin: 0;
  display: table;
  color: #999;
}

/* Screens page */
.section-dashboard-screens {
  margin: 6px 0 50px 0;
}

.sectionTitle {
  border: 0;
  font-size: 18px;
  padding-bottom: 3px;
  margin: 9px 0 0 0;
}

.sectionButtons {
  float: right;
  text-align: right;
}

.sectionButtons button {
  margin-left: 15px;
}

/* Schedule pages */
.headderSteps,
.headerAction,
.scheduleheaderSection {
  width: 100%;
  float: left;
}

.schedulePlaylistSection {
  margin: 40px auto 15px;
  text-align: center;
}

.headderTitle,
.subtitle {
  width: 60%;
  float: left;
  line-height: 22px;
}

.firstsubtitle {
  font-size: 14px;
  margin: 5px 0 0 0;
  color: #999;
}

.orangeColor {
  color: #d63a5b;
  margin: 0 5px;
}

.orangeColor.firstWord {
  margin: 0 5px 0 0;
}

.allStepsWrapper {
  position: relative;
  float: right;
  height: 60px;
  margin-right: 2%;
  text-align: right;
}

.stepWrapper {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  background: rgba(255, 255, 255, .4);
  background-color: rgba(255, 255, 255, 0.4);
  margin-right: 165px;
  position: relative;
  z-index: 99;
  margin-top: 9px;
  font-size: 18px;
  color: #162335;
  font-weight: bold;
}

.stepWrapper.two {
  margin-right: 10px;
}

.stepWrapper.finished {
  background-color: #52c4c5;
  border: 5px solid #748299;
}

.stepWrapper.active {
  background: #f74866;
  border: 5px solid #f74866;
}

.stepNumber {
  line-height: 30px;
  width: 30px;
  color: #fff;
}

.stepTitle {
  margin-top: 10px;
  display: inline-block;
  text-align: center;
  position: relative;
  left: -30px;
  color: #fff;
  white-space: nowrap;
  font-size: 12px;
}

.stepsSeperator {
  position: absolute;
  width: 166px;
  height: 1px;
  background-color: rgba(255, 255, 255, .3);
  top: 50%;
  right: 50px;
}

.ActionButton {
  float: right;
  margin-top: 25px;
}

.ActionButton button {
  margin: 0px;
}

.TypeButtons {
  display: inline-block;
}

.TypeButtons button {
  border: 1px solid #a5a5a5;
  border-radius: 50%;
  width: 105px;
  height: 105px;
  text-align: center;
  background: none;
  float: left;
  font-size: 14px;
  white-space: normal;
  word-wrap: break-word;
  margin-right: 15px;
  color: #fff;
}

.TypeButtons button:hover,
.TypeButtons button:focus,
.TypeButtons button:visited {
  background: none;
  background-color: none;
  border: 1px solid #a5a5a5;
}

.TypeButtons button.active {
  color: #fc6e51;
  background: #fff;
}

.scheduleTypeOnceFields {
  margin: 30px auto;
  max-width: 770px;
}

.scheduleTypeOnceFields .box {
  float: left;
  background: #252525;
  padding: 40px 20px 20px 20px;
  margin: 10px 20px;
  width: 340px;
}

.dateTimeField {
  margin: 0 0 20px 0;
  display: table;
}

.dateTimeField label {
  color: #999;
  margin: 10px 15px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
  float: left;
}

.react-datepicker-wrapper {
  float: left;
  width: 135px;
}

.dateTimeField input {
  color: #999;
  border-radius: 2px;
  background-color: #423f3f;
  width: 120px;
}

.scheduleTypeSectionTitle {
  font-weight: 600;
  color: #999;
  padding: 10px 15px 5px 0;
  border-bottom: 1px solid transparent;
  border-color: #333;
  margin: 0 0 10px 0;
  text-align: left;
  font-size: 16px;
}

.scheduleTypeRecurFields {
  margin: 30px auto;
  max-width: 660px;
}

.scheduleTypeRecurFields .dateTimeField {
  margin: 20px 2% 20px;
  width: 46%;
  display: inline-block;
}

.mainLabel {
  color: #999;
  margin: 0 15px 0 0;
}

.scheduleTypeRecurFields input[type="checkbox"] {
  background: none;
  margin-left: 15px;
}

/* LICENCE PLAYLIST START  */

.license-page .form-control {
  padding: 0.375rem 0.75rem;
}

.license-page .btn-success {
  margin: 0 0 0 10px;
}

.licence-table-head {
  background-color: #333;
  color: #ccc;
  font-weight: 800;
}

.licence-table-head tr {
  border: 1px solid #000;
}

.licence-table-body {
  background-color: #474747;
  border: 1px solid #000;
  color: #ccc;
}

.licence-table-body tr {
  border: 1px solid #000;
}

.licence-table-head th {
  border: 1px solid #000;
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: #000;
}

.scroll {
  overflow: auto;
  display: inline-block;
  width: 100%;
  height: calc(100vh - 200px);
  margin-top: 20px !important;
}

.main-title-hold .v-align p {
  margin-bottom: 5px;
}

.offer-position {
  position: absolute;
  top: 73px;
  right: 182px;
}

.licence-mainblock .card {
  background-color: #0d0d0d;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0px;
  margin-bottom: 15px;
}

.licence-mainblock .card .card-body {
  background-color: #191919;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0px;
  margin: 10px 10px 10px 10px;
  padding: 10px;
}

.licence-mainblock .card .card-body p {
  font-size: 14px;
  color: #f60;
}

.licence-mainblock .card .card-body h5 {
  font-size: 14px;
}

/* LICENCE PLAYLIST END  */
#sidebar .menu-sh-item {
  display: none;
}

#sidebar.active .menu-sh-item {
  display: block;
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {
  .container {
    padding: 0;
    margin: 15px 15px 15px 90px;
    width: 85%;
  }
}

.eviewtm {
  margin: 11px 0px 5px 10px;
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  width: 2.2rem !important;
}

#dashbaord-create-playlist .stepWrapper.two {
  margin-right: 10px;
  padding: 5px 0 0 0;
}

.scheduleheaderSection .stepWrapper {
  z-index: 0;
}

#priority {
  background: #222;
  border: 0;
  color: #ccc;
  padding: 8px;
  border-radius: 2px;
  display: block;
}

.priorityField {
  display: inline-block;
  margin: 0;
  float: left;
  padding: 20px;
}

.priorityField label {
  float: left;
  padding-right: 20px;
  padding-top: 10px;
}

.priorityField p {
  padding: 15px 0;
  color: #999;
}

.scheduleTypeRecurFields .priorityField label {
  color: #999;
}

.scheduleTypeRecurFields .priorityField {
  margin: 0 0 0 20px;
}

.calPriorityField {
  display: inline-block;
  margin-top: 15px;
}

.calPriorityField label {
  color: #999;
  float: left;
  padding: 10px 20px 0 0;
}

.calPriorityField p {
  color: #999;
  padding: 10px 0 0 0;
}

.calPriorityField select {
  background: #eee !important;
  color: #666 !important;
}
/* tablets portrait */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)
and (orientation : portrait) { 

.container {
    padding: 0;
    margin: 15px 15px 15px 80px;
    width: 85%;
}

/* Index.css */
.overlay {
    background: rgba(0,0,0,0.7);
}

.fade.modal.show {
    z-index: 9999;
}

.previewContent {
    width: 72% !important;
}
	
.DashboardPlaylistsCard_DashboardPlaylistsCard__ch_Do {
    height: calc(100vh - 565px);
}

.section-dashboard-screens .row.screens {
    max-height: calc(100vh - 730px) !important;
}

.DashboardPlaylistsCard_DashboardPlaylistsCard__ch_Do ul.list-group {
    display: block;
}

.DashboardPlaylistsCard_DashboardPlaylistsCard__ch_Do li {
    width: 49%; float: left;
}

li.list-group-item:nth-child(odd) {
    margin-right: 1%;
}

li.list-group-item {
    height: 110px;
}

/* Dashboard Playlist Block */

.DashboardPlaylistsCard_DashboardPlaylistsCard__ch_Do li .DashboardPlaylistsCard_actions__3WBxy, .DashboardPlaylistsCard_DashboardPlaylistsCard__ch_Do li .DashboardPlaylistsCard_title__22J81, .DashboardPlaylistsCard_DashboardPlaylistsCard__ch_Do li .DashboardPlaylistsCard_updated__1SBDo {
    width: calc(100% - 110px);
    margin-left: 37%;
}


/* Create Playlists 1000 */
.left-title-hold {
    text-align: left!important; width: 57%;
}

.main-title-hold .btn-group .form-control {
    width: 230px; margin: 0 5px 0 0;
}

.left-title-hold {
    width: 50%;
}

.right-title-hold {
    width: 50%; 
}

button#screen_btnSave_set {
    float: right !important;
}

.stepsSeperator {
    left: inherit !important;
}

.allStepsWrapper {
    text-align: right !important; margin-left: 0%;
}

.user-content a {
    background-position: center -383px;
}

.create-playlist-mainblock ul#tabs-nav li {
    padding: 12px 10px 5px;
}

.img-content-block {
    width: 25%!important;
}

.onethird #tabs-content {
    height: 290px;
}

.img-content-block {
    margin-top: 5px;
}

.onethird {
    width: 100%;
    height: 360px;
}

.no-pad {
    margin-top: 0;
}

.scheduleheaderSection .ActionButton {
    margin-top: 10px;
}

.scheduleTypeOnceFields .box {
    margin: 10px 1%;
    width: 48%;
}

.popup-content {
    height: auto; max-height:calc(100vh - 210px);
}

.group-item {
    width: 165px;
}

.info-list-settings {
    float: right;
}

.info-list-settings a {
    float: right;
}

.playlist-scroll {
    height: calc(100vh - 285px);
}

.modal.show .modal-dialog {
    width: 92%;
}

.add-screens .carousel .slider-wrapper, .add-screens-page .group-wrapper  {
    height: auto;
}


.add-screens-page .group-wrapper-here {
    height: 200px;
    width: 29% !important;
}

.play-search-nm {
    padding: 0;
}

.add-screens.group-list-main .carousel .control-dots {
    bottom: 0px;
}

.modalClose {
    right: 5px;
    top: 5px;
}

#TermsModal_1, #TermsModal_2{
background: rgba(0, 0, 0, 0.7);
}

/* library.css */
.page-media .folder-btns-wrapper {
    padding: 0 5px !important;
}

.library-last-selection {
    margin-left: 20px !important;
}

.add-folder .modal.show .modal-dialog, .library-delete-popup .modal.show .modal-dialog, .library-upload	 .modal.show .modal-dialog {
    width: 500px;
}

.main-container.container.user-redirect-pages{
    margin: 40px 15px 15px 40px;
}

.page-media .folder-btns-wrapper {
    border-right: 0;
    margin-left: 0 0 0 10px !important;
}

#Library_ViewBy, .lib-thumbview-normal, .lib-listview-normal { display:none !important; }

.page-media .lib-delete-folder, .page-media .lib-new-folder, .page-media .lib-rename-folder {
        max-width: 45px;
    margin: 7px 0px !important;
}

.page-media .folder-btns-wrapper-right {
    margin: 0 !important;
    width: 65px;
    height: 70px;
    padding: 0;
	border-right: 0;
}

.page-media .folder-btns-wrapper {
    margin-left: 5px !important;
}

.library-last-selection {
    display: none;
}



.addUserMessages .media-messages-form {
    display: table;
    width: 100% !important;
}

.tui-image-editor-container.bottom {
    width: 100% !important;
    height: 580px !important;
}

.imageSection {
    width: 60%;
}

.media-messages-content {
    width: 40%;
}

.imagesResult {
    height: 140px;
}

.imageBox {
    float: left;
    width: 160px;
}

.addUserMessages .library-inner .react-tabs .react-tabs__tab-panel {
    height: 216px !important;
}

.addUserMessages .library-left-section {
    height: auto;
}

.addUserMessages #title_div {
    margin: 0;
}

.addUserMessages .media-messages-form {
    bottom: -20px;
    left: 0;
}

.item-list {
    height: calc(100vh - 370px);
}

.calendardiv {
    height: 450pt;
}

.manage-users #react-tabs-3 .views-exposed-widget.views-submit-button {
    margin: 0 0 10px 5px;
}

.manage-users #react-tabs-5 .views-exposed-widget.views-submit-button {
    float: left;
    margin: 20px 0 10px 5px!important;
}

input.theme-search-box {
    background: #222;
    min-width: 250px;
}

.search-box div.search-input-box {
    width: 40%;
    float: left;
}

.react-confirm-alert-overlay.undefined {
    top: -250px;
}

th.views-field.field-mpdids, th.views-field.field-unit-streetaddress {
    min-width: 180px;
}

.device-list-tabs #react-tabs-1 .views-exposed-widget.sort-order {
    width: 85px !important;
}

.device-list-tabs #react-tabs-1 .views-exposed-widget.sort-by {
    width: 125px !important;
    clear: inherit;
}

#react-tabs-7 .views-exposed-widget.views-submit-button {
    float: left;
    margin: 0 0 10px 5px;
}

.calendar-popup .scheduleTypeOnceFields .box {
    width: 125px!important;
}

.calendar-popup .TypeButtons {
    width: 270px;
    min-height: 240px;
}

.calPriorityField {
    display: inline-block;
    margin: 15px 15px 0 20px;
}

.dateTimeField label {
    width: auto;
}

.calendar-popup .weekDays-selector {
    margin: 0 0 0 20px;
    float: left;
}

.scheduleTypeRecurFields .dateTimeField {
    margin-left: 20px !important;
}

.scheduleTypeRecurFields .calPriorityField p {
    color: #999;
    padding: 20px 0 0 0;
}

.right-title-hold {
    float: right !important;
    width: 45%; margin: 25px 0 0 0;
}

button {
    line-height: 13px !important;
}

.manage-users .tbody-div {
    height: calc(100vh - 400px);
}

.container .container {
    margin-left: 20px;
}

.previewContent .control-arrow {
    top: -75%!important;
}

.previewBanner .control-arrow {
    bottom: 48% !important;
}

.previewContent .control-prev.control-arrow {
    left: 5px;
}

.previewContent .control-next.control-arrow {
    right: 5px;
}

.previewBanner .control-next.control-arrow {
    right: -10px;
}

.create-playlist-mainblock .scroll {
    height: 285px;
}

.selectPlaylistSection .container {
    margin: 0 0 0 !important;
}

.group-list-main.group-slider-cs .carousel .slider-wrapper {
    max-height: calc(100vh - 260px);
    height: auto;
}

.group-list-main.group-slider-cs .carousel .slide {
    height: auto;
}

.page-media .folder-btns-wrapper-right {
    border-left: 3px solid #000;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
    z-index: 99999;
}

.right-title-hold.offer-position {
    position: absolute;
    top: 30px;
    right: 7%;
}

#react-tabs-7 .views-exposed-widget.sort-order {
    width: 90px !important;
}

#react-tabs-7 .views-exposed-widget.sort-by {
    width: 120px !important;
    clear: inherit;
}

#react-tabs-7 .views-exposed-widget.views-submit-button {
    float: left;
    margin: 17px 0 10px 5px;
}

.manage-users #react-tabs-7 .views-exposed-widget.views-submit-button {
    margin-top: 0;
    margin-bottom: 15px;
}

.manage-users #react-tabs-9 .views-exposed-widget.views-submit-button {
    float: left;
    clear: both;
    margin: 0 0 15px 7px;
}

.devices-unassigned-tab .views-exposed-widget.sort-order {
    width: 120px !important;
}

.groups-popup.modal.fade .modal-dialog {
    width: 88%;
}


}





/* tablets Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  {

.container {
    padding: 0;
    margin: 15px 15px 15px 80px;
    width: 90%;
}

.previewContent .control-arrow {
    top: -55%!important;
}

.previewBanner .control-arrow {
    bottom: 57% !important;
}

.add-screens.group-list-main .carousel .slider-wrapper {
    background: transparent;
}
.add-screens .carousel .slider-wrapper {
    width: 90%;
    height: auto;
    max-height: 540px;
}

.add-screens.group-list-main .carousel .control-dots {
    bottom: 5px;
}

.add-screens-page .group-wrapper {
    height: auto;
}

.popup-content {
    height: auto;
}

.tui-image-editor-container.bottom {
    width: 100% !important;
    max-height: 600px !important;
}

.item-list {
    height: calc(100vh - 350px);
    overflow: auto;
}

.library-right-section nav.pagination {
    margin: 5px 0 0px;
}
}
.device-list-main {
    background: #111;
}

.views-exposed-widget {
    width: 24%;
    float: left;
    margin: 0 5px 10px 5px;
}

.views-exposed-widget.cpid {
    width: 26%;
}

.views-exposed-widget.StreetAddress {
    width: 20%;
}

.views-exposed-widgets {
    background: #333;
    padding: 10px 15px 0 15px;
    margin: 0;
    display: table;
    width: 100%;
}

.device-list-main input {
    min-height: 32px;
    background: #222;
    border-radius: 2px;
    font-weight: 100;
    padding: 8px;
    border: 0;
    color: #ccc;
    margin: 0;
}

.react-tabs__tab {
    background: #1f1f1f;
    padding: 8px 20px;
    margin: 0 5px 0 0;
    bottom: 0;
}

.react-tabs__tab--selected {
    background: #333 !important;
    color: #fff !important;
    border: 0;
    border-radius: 2px 2px 0 0;
    padding: 8px 20px;
    bottom: -1px;
}

.views-exposed-widgets label {
    font-size: 13px;
}

.tbody-div {
    background: #333;
    color: #fff;
}

.react-tabs__tab-list {
    border-bottom: 0;
    margin: 0;
    padding: 0;
}

.views-exposed-widgets .form-text {
    background: #222;
    border: 0;
    color: #fff;
    padding: 5px 10px;
    font-size: 14px;
}

.views-exposed-widgets .form-select {
    background: #222;
    border: 0;
    color: #fff;
    font-size: 14px;
    background-image: none !important;
    margin-top: 3px;
    padding: 8px 10px;
}

table tr td {
    color: #ccc;
    border-color: #222;
    padding: 0.5rem 0.8rem 0.5em 0.5rem !important
}

table tr th {
    padding: 5px 8px;
    border: 1px solid #000 !important;
    color: #fff;
}

label input {
    min-height: auto;
}

.sort-by {
    width: 150px !important;
    clear: left;
}

#react-tabs-3 .sort-by {
    clear: none;
}

.sort-order {
    width: 120px;
}

.form-control {
    padding: 2px 0;
}

.views-exposed-widget.views-submit-button {
    margin: 20px 0 0 5px;
    max-width: 190px;
}

input[type=checkbox] {
    display: inline-block;
    font-family: "FontAwesome", sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
    outline: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
}

input[type=checkbox]:before {
    font-family: "FontAwesome", sans-serif;
    content: "\f096";
    visibility: visible;
    font-size: 16px;
    color: #fff;
}

input[type=checkbox]:checked:before {
    content: "\f046";
    color: #ff9900;
    font-size: 16px;
}

.Devicelist-heading {
    display: table;
    width: 100%;
    margin: 0;
}


.device-list-tabs .tbody-div,
.manage-users .tbody-div {
    overflow-y: auto;
    height: calc(100vh - 390px);
    display: block;
    width: 100%;
    background: #333;
    overflow-x: hidden;
}

span.assign-default-message {
    color: #666;
    text-align: center;
    margin: 30px auto 0 auto;
    display: table;
    font-size: 14px;
}

.device-list-footer {
    color: #999;
    background: #333;
    padding: 0;
    width: 100%;
    text-align: right;
    height: 35px;
    position: relative;
}

.device-list-footer a {
    color: #f74866;
    text-align: center;
    margin: 0 auto 0 auto;
    font-weight: 700;
    display: block;
    font-size: 15px;
    width: 100px;
    padding: 7px 0 0 0;
}

span.list-count {
    position: absolute;
    right: 10px;
    top: 0;
}

.form-item.form-type-checkbox.checkbox {
    margin: 0;
    padding: 0;
    width: 3px;
}

input#unit-number {
    width: 100%;
}

.list-items {
    font-size: 12px;
    padding: 5px;
    display: table;
    width: 100%;
    border-radius: 3px;
}

.list-items:hover {
    background-color: #111;
    cursor: pointer;
}

#list-dropdown {
    position: absolute;
    background: #222;
    width: auto;
    z-index: 99;
    left: 1px;
    border-radius: 3px;
    top: 37px;
    max-height: 300px;
    overflow-y: auto;
}

#list-dropdown .list-items {
    min-width: 110px;
}

button.btn-load-more {
    background: none;
    border: 0;
    font-size: 16px;
    font-weight: bold;
    margin: 0 auto;
    display: table;
    text-align: center;
    padding: 8px 0 0 0;
    color: #f74866;
}

.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-radius: 3px;
}

#edit-submit-devices-list:hover {
    color: #fff;
    background-color: #d62d51;
    border-color: #d62d51;
}

#edit-submit-devices-list:focus {
    background-color: #d62d51;
}

#edit-reset:hover {
    background: #444 !important;
    color: #fff;
}

.unit-number {
    width: 160px;
}

.city {
    width: 175px;
}

.devices-unassigned-tab .tbody-div {
    height: calc(100vh - 385px);
}

.select-one {
    color: #666;
    margin: 0 auto;
    text-align: center;
    display: block;
}

.assign-default-message-1 {
    margin: 10px 0;
}

#edit-userrole input,
#edit-userrole label {
    float: left;
    padding: 0;
    margin: 0;
    min-height: auto;
}

#edit-userrole label {
    padding: 0 15px 0 5px;
    color: #333;
}

.manage-users .assignclass {
    padding: 25px;
}

.manage-users span.assign-default-message {
    margin: 10px auto 0 auto;
}

.manage-users button#disable {

    display: block;
}

.manage-users div#edit-field-disable-status-value-1-wrapper {
    margin: -5px 0 0 5px;
}


.css-26l3qy-menu {
    right: auto !important;
    bottom: 85% !important;
    top: inherit !important;
    width: 250px !important;

}

.css-1n7v3ny-option {
    background-color: #222 !important;
}

.css-yt9ioa-option {
    padding: 6px 12px !important;
    background-color: #222 !important;
}

.css-4ljt47-MenuList {
    background: #333 !important;
}

.css-1pahdxg-control {
    min-width: 250px !important;
    border-color: transparent !important;
    box-shadow: 0 0 0 1px #999 !important;
}

svg.css-tj5bde-Svg {
    color: #666;
}

.css-1s2u09g-control {
    min-height: 28px !important;
    color: #222222 !important;
    min-width: 250px !important;
    width: auto !important;
    float: left !important;
}

.autocomplete_email button#edit-add-users {
    float: left;
    margin: 5px 10px 0 10px;
}

.autocomplete_email button#edit-unassign {
    margin: 5px 0 0 0;
}

.add-uers-options #edit-add-users {
    margin: auto 0;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    max-width: 90px;
}

.add-uers-options #edit-unassign {
    float: left;
    margin: auto 0 auto 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    max-width: 90px;
}

.add-uers-options,
.action-buttons {
    display: flex;
}

.css-g1d714-ValueContainer {
    margin: 0 10px 0 0;
    max-height: 70px;
    overflow-y: auto !important;
    padding-left: 0 !important;
}

.assignclass {
    background: #fff;
    border: 5px solid #ccc;
    margin: 0;
    border-radius: 0;
    height: 90px;
    padding: 5px;
}

.css-12jo7m5 {
    font-size: 90% !important;
}

.email #list-dropdown {
    max-height: 200px;
    overflow-y: auto;
}

.device-list-tabs .react-tabs__tab-panel,
.manage-users .react-tabs__tab-panel {
    padding: 0;
    height: calc(98.5vh - 117px);
}

.device-list-tabs .react-tabs__tab,
.manage-users .react-tabs__tab {
    padding: 10px 15px !important;
    color: #999;
    width: auto;
    text-overflow: inherit;
    margin: 0 5px 0 0 !important;
}

#react-tabs-1 .city {
    width: 135px;
}


.assignclass-child {
    display: flex;
    margin: 0 auto;
    vertical-align: middle;
    height: 100%;
}

button#edit-add-users {
    margin: auto 0;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    max-width: 90px;
    min-width: 88px;
}

button#edit-unassign {
    margin: auto 0;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    min-width: 70px;
    max-width: 90px;
}

#autocomplete_email {
    max-width: 98%;
    display: flex;
    vertical-align: bottom;
    margin: auto 0;
    width: auto;
}

#autocomplete_email label {
    width: 70px;
    float: left;
    margin: auto;
    color: #333;
    padding-left: 10px;
}

.list-count {
    padding: 10px;
}

#edit-remove-users {
    margin: auto 0;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    min-width: 70px;
    max-width: 90px;
    margin-right: 5px;
}

.manage-users .react-tabs .react-tabs__tab-panel {
    height: auto !important;
    overflow: hidden;
}

div#edit-userrole label,
div#edit-userrole input {
    margin: 8px 0 0 0;
}

.or-action {
    color: #999;
    margin: 0 10px 0 5px;
}

.element_input>button {
    border: 0;
    border-radius: 2px;
    padding: 10px 12px 10px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
}

.manage-users .tbody-div {
    height: calc(100vh - 330px);
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {

    .manage-users .tbody-div {
        overflow-x: auto;
    }

    .page-media .views-exposed-widgets .views-widget {

        width: 97%;
    }

    .device-list-tabs .tbody-div,
    .manage-users .tbody-div {
        width: 100%;
        overflow-x: auto;
    }
}

.devices-unassigned-form .views-exposed-widget.unit-number {
    width: 152px !important;
}

.devices-unassigned-form .views-exposed-widget.views-submit-button {
    max-width: 210px !important;
}

.devices-unassigned-form .views-exposed-widget.StreetAddress {
    width: 260px;
}

.devices-unassigned-form .views-exposed-widget.cpid {
    width: 32%;
}

#edit-remove-users-submit {
    margin: auto 0;
    margin-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    min-width: 70px;
    max-width: 90px;
}

.email-moretooltip {
    background: #666;
    padding: 2px 10px 2px 10px;
    border-radius: 20px;
    margin: 2px 0 0 10px;
    width: auto;
    display: inline-block;
    font-size: 12px;
}

.device-list-tabs .views-exposed-widget.views-submit-button {
    margin: 20px 0 0 5px;
    max-width: 230px;
}

.manage-users #edit-submit-devices-list,
.manage-users #history,
.manage-users #active {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
}

.devices-unassigned-form .views-exposed-widget.sort-by {
    clear: inherit;
}

#pending .views-exposed-widget.views-submit-button {
    max-width: 350px;
    margin: 21px 0 0 5px;
}

.device-not-found {
    background: #333;
    text-align: center;
    margin-top: 1px;
    padding: 90px 0;
}


#edit-assigngroup-body #edit-remove-users,
#edit-assigngroup-body #edit-add-users,
#edit-assigngroup-body #edit-unassign {
    max-width: 200px;
}

.assignclass #autocomplete_email label {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 55px;
}

.waiting-actions #edit-userrole {
    float: left;
    margin: 0 0 0 30px;
}

.css-1hwfws3 {
    max-height: 72px;
    overflow-y: auto !important;
}

.manage-users .views-exposed-widgets .unit-number {
    min-width: 190px;
}

.manage-users .views-exposed-widgets div#edit-field-disable-status-value-1-wrapper {
    margin: 0 0 0 5px;
    max-width: 150px;
}

.manage-users .views-exposed-widgets .form-select {
    margin-top: 4px;
}

.manage-users .views-exposed-widget.views-submit-button {
    max-width: 350px;
    width: auto;
    margin-top: 21px;
}

.manage-users .views-exposed-widgets .form-text {
    padding: 7px 10px;
}

.user-default-text td {
    text-align: center;
    padding-top: 110px !important;
}

.devices-unassigned-tab .views-exposed-widget {
    min-height: 52px;
}

#autocomplete_email div[class*="-control"] {
    min-height: 28px !important;
    color: #222 !important;
    min-width: 250px !important;
    width: auto !important;
    float: left !important;
}

#autocomplete_email div[class*="-ValueContainer"]div[id^="react-select-"][id$="-listbox"] {
    margin: 0 10px 0 0;
    max-height: 70px;
    overflow-y: auto !important;
    padding-left: 0 !important;
}

#autocomplete_email div[class*="-menu"] {
    right: auto !important;
    bottom: 85% !important;
    top: inherit !important;
    width: 250px !important;
}

#autocomplete_email div[class*="-MenuList"],
div[id^="react-select-"][id$="-listbox"] {
    background: #333 !important;
    outline: 0px !important;
}

div[id^="react-select-"][id$="-listbox"] div div {
    background: #333;
}

div[id^="react-select-"][id$="-listbox"] div div:hover {
    background: #111;
}

#autocomplete_email div[class*="-control"] div:nth-child(1) {
    max-height: 72px;
    overflow-y: auto !important;
}

.units-report .tbody-div {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 270px);
    display: block;
}

.units-report td.views-field.field-cdate {
    min-width: 160px;
}

.units-report td.views-field.field-addr {
    min-width: 200px;
}

.units-report td.views-field.field-assign, td.field-addr, td.field-sdate {
    min-width: 250px;
}

.units-report td.views-field.field-sdate {
    min-width: 203px;
    display: block;
}

.units-report td.views-field.field-mpdids {
    min-width: 280px;
}

.units-report td.views-field.field-pname {
    min-width: 132px;
}

.units-report .td-block {
    display: flex;
    width: 200px;
}

.units-report th.views-field.field-sdate {
    min-width: 200px;
}

.units-report th.views-field.field-pname {
    min-width: 140px;
}
.units-report td.views-field.field-rec {
    min-width: 110px;
}
.units-report td.views-field.field-wdays {
    min-width: 120px;
}
.units-report th.views-field.field-wdays {
    min-width: 120px;
}
.units-report td.views-field.field-cdown, td.views-field.field-forev, th.views-field.field-forev, th.views-field.field-cdown, th.views-field.field-cuid, th.views-field.field-rec {
    min-width: 125px;
}
.units-report .inner-table td {
    background: transparent;
    border: 0;
}
.export-excel {
  height: 45px;
  width: 45px;
  border-radius: 5px;
  background: #fff;
  float: right;
}
 
 .export-excel img {
  height: 30px;
  margin: 7px auto;
  display: block;
}

.export-exceldisable a:hover {
  cursor: not-allowed;
}
.export-exceldisable {
  height: 45px;
  width: 45px;
  border-radius: 5px;
  background: #fff;
  float: right;
  opacity: 0.5;
}

 .export-exceldisable img {
  height: 30px;
  margin: 7px auto;
  display: block;
}

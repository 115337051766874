/* --------------------------------------------------------------------------------------------
Project Name: CMS
File Name: Playlist.css
File Description: Styles playlists page of CMS portal

Copyright 2021 Otis Elevator Company
The software on this media is protected by law. Unauthorized use, reproduction or reverse engineering without the express permission
of Otis Elevator Company. is strictly prohibited.
Unpublished Work. All Rights Reserved.
------------------------------------------------------------------------------------------------- */
/* Screens accordions */

/* Screens page */
.section-dashboard-screens {
  margin: 6px 0 5px;
}

.sectionTitle {
  border: 0;
  font-size: 18px;
  padding-bottom: 3px;
  margin: 9px 0 0 0;
}

.sectionButtons {
  float: right;
  text-align: right;
}

.sectionButtons button {
  margin-left: 15px;
}

/* Screens Playlists */
.section-dashboard-playlists {
  margin-top: 5px;
}

.section-dashboard-playlists .sectionTitle {
  margin: 0;
}

/* Pagination */
nav.pagination {
  text-align: center;
  margin: 5px 0px 10px 0px;
  display: inline-block;
  width: 100%;
}

nav.pagination ul {
  display: block;
  margin: 0;
  padding: 0;
}

nav.pagination ul li {
  display: inline-block;
}

nav.pagination ul li.disabled {
  display: none;
}

nav.pagination ul li a {
  background: none;
  color: #fff;
  border: 0;
  padding: 5px 6px;
  position: relative;
  float: left;
  margin-left: -1px;
  line-height: 1.42857143;
}

nav.pagination ul li a.active {
  background: #333 !important;
  color: #f74866;
}

/* Modal */
.modal-dialog {
  width: 700px;
  max-width: 700px;
}

.modal-content {
  border-radius: 0;
}

.modal-body {
  padding: 10px;
}

.modalClose {
  color: #f74866;
  font-size: 30px;
  line-height: 25px;
  width: max-content;
  position: absolute;
  right: -8px;
  top: -9px;
  z-index: 9;
}

.topModal .modal-dialog {
  width: 600px;
  max-width: 600px;
}

.topModal .modal-content {
  background: #f2f2f2;
  color: #111;
}

.topModal .modal-header {
  padding: 10px 15px 5px 15px;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 0 10px 0;
}

.topModal .modal-header .modal-title {
  font-size: 16px;
}

.topModal .modal-body {
  margin: 10px 0;
}

.close {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 0 15px;
}

/* CArousel */
.carousel.slide {
  height: inherit;
}

.carousel-item {
  width: 78%;
  margin: 0 11%;
  height: inherit;

}

.carousel-inner {
  height: inherit;
}

.carousel-item img {
  border: 2px solid rgba(255, 255, 255, 1);
  height: inherit;
}

.carousel-indicators {
  margin-bottom: 10px;
}

.carousel-indicators [data-bs-target] {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid #fff;
}

.carousel-indicators button.active {
  background-color: #fff;
}

.actionIcons {
  padding: 5px 10px 0 0;
  width: 25px;
  margin: 0 5px 0 0;
  display: inline-block;
  position: relative;
  font-size: 16px;
}

.actionIcons a {
  color: #fff;
}

.actionIcons button {
  border: none;
  background: transparent;
  padding: 0;
  color: #fff;
}

.actionIcons .error {
  color: #ff3333;
}

.actionIcons .body .error {
  float: right;
}

.actionIcons .themedownloaderror {
  background: #f33;
  font-size: 12px;
  border-radius: 10px;
  width: 15px;
  height: 15px;
  display: block;
  text-align: center;
  position: absolute;
  top: -25px;
  left: 10px;
}

.drop-down-menu_EMFQP {
  left: -10px;
  padding-top: 10px;
  z-index: 9999 !important;
}

.UnopDropdownInner {
  width: 300px;
  background: #222;
  display: table;
  border: 1px solid #333;
  border-radius: 5px;
  color: #eee;
  font-size: 13px;
}

.UnopDropdownInner .emptyPlaylist {
  padding: 15px 15px 10px 15px;
  margin: 10px 0 0;
}

.UnopDropdownInner .notEmptyPlaylist {
  padding: 15px;
}

.UnopDropdownInner .ActivePlaylist {
  margin-top: 15px;
}

.UnopDropdownInner .ActivePlaylist svg {
  float: right;
}

.notificationContent {
  padding: 15px 15px 0;
}

.notificationContent .title {
  margin-bottom: 12px;
}

.notificationContent .body {
  width: 100%;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 12px;
}

.UnopDropdownInner .notificationFooter {
  background: #444;
  margin-top: 0;
  padding: 10px 10px 10px;
  border-radius: 0 0 3px 3px;
  color: #ccc;
}

.UnopDropdownInner:hover {
  cursor: default;
}

.screensLoading {
  background: none repeat scroll 0 0 #222;
  padding: 8px 0;
  margin: 0 12px;
  text-align: center;
  width: calc(100% - 24px);
}

.screens {
  margin-top: 10px;
}

.screens .accordion-item {
  background: none;
  border: none;
  margin-bottom: 5px;
  position: relative;
}

.screens button.accordion-button {
  background: none repeat scroll 0 0 #222;
  border: 0;
  border-radius: 0;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  outline: 0 none;
  padding: 0.3em 0.5em 0.5em 2.2em;
  position: relative;
}

.screens .accordion-button:not(.collapsed),
.screens .accordion-item:first-of-type .accordion-button,
.screens .accordion-item:last-of-type .accordion-button.collapsed,
.screens .accordion-button:focus .screens .accordion-button:active {
  background: none repeat scroll 0 0 #222;
  box-shadow: none;
  border: none;
  border-radius: 0;
}

.accordion-body {
  padding: 5px;
  background: #333;
}

.screens .accordion-button:after {
  background-image: url(../Assets/Sprite-icon2.png);
  right: 10px !important;
  background-position: -78px -273px;
  width: 11px;
  height: 7px;
  background-repeat: no-repeat;
  background-size: auto;
}

.screens .offline {
  color: gray;
}

.screens .online {
  color: green;
}

.addPlaylistModal .modal-header {
  padding: 10px 20px !important;
  border-bottom: 1px solid #ddd !important;
}

.screensButtons button {
  margin-right: 0;
}

#multiple-device-setting {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.grayBG {
  background-color: #e0e0e0;
  padding: 10px 20px 20px 20px;
  margin-top: 10px;
  display: table;
  width: 100%;
}

input#floorInfoName {
  color: #333;
  background: #fff;
  border: 0;
}

h3#DirectoryName {
  text-align: left;
}

.nocreatenew .AddPlaylistsbuttons {
  width: auto;
}

.createnew .start-button {
  margin: 10px auto 0 auto;
  text-align: center;
}

.createnew .buttonSeperator {
  float: none;
  text-align: center;
  display: table;
  margin: 10px auto 10px auto;
}

.createnew button.existingPlaylistBtn {
  margin: 0 auto;
  text-align: center;
  display: table;
  float: none;
}

.createnew button.createPlaylistBtn {
  float: none;
  margin: 0 auto;
  text-align: center;
  display: table;
}

.createnew .createPlaylisttitle {
  text-align: center;
}

.createnew .AddPlaylistsbuttons {
  width: 80%;
}

.AddPlaylistsbuttons {
  width: 80%;
  display: table;
  margin: 0 auto;
}

.createPlaylistBtn {
  color: #fff;
  background-color: #f74866;
  border: none;
  padding: 5px 15px;
  max-width: 100%;
  line-height: 14px;
  height: 36px !important;
  float: left;
}

.existingPlaylistBtn {
  color: #fff;
  background-color: #f74866;
  border: none;
  padding: 5px 15px;
  max-width: 100%;
  line-height: 14px;
  height: 36px !important;
  float: left;
}

.createPlaylistBtn:hover,
.existingPlaylistBtn:hover {
  background-color: #d62d51;
}

.buttonSeperator {
  width: 80px;
  text-align: center;
  margin: 10px 0;
  float: left;
}

.page-screens #edit-choose-playlist .form-item.form-type-radio.radio label.control-label {
  display: inherit;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #999;
  line-height: 24px;
  height: 100%;
}

.selectPlaylistSection .radio label:before {
  content: '\f05d' !important;
  display: block;
  width: 25px;
  height: 25px;
  z-index: 500;
  position: absolute;
  font-family: "FontAwesome", sans-serif;
  right: 10px;
  font-size: 25px;
  color: #444;
}

.selectPlaylistSection .radio.checked label:before {
  content: '\f05d' !important;
  display: block;
  width: 25px;
  height: 25px;
  z-index: 500;
  position: absolute;
  font-family: "FontAwesome", sans-serif;
  right: 10px;
  font-size: 25px;
  color: #f74866;
}

.playlist-card input {
  display: none;
}

.selectPlaylistSection .container {
  overflow-y: auto;
  height: calc(100vh - 200px);
  width: 100%;
  overflow-x: hidden;
}

.choose-label {
  display: table;
  float: left;
  clear: both;
  margin: 15px 0 5px 0px;
}

.playlist-card:hover {
  cursor: pointer;
}

.schedulePlaylistSection {
  margin-top: 0;
}

.playlistDetails {
  float: left;
}

.section-dashboard-screens .row.screens {
  margin-top: 7px;
}

.modalMessage.diffscreentype {
  display: table;
  width: 100%;
  padding: 20px;
}

.diffscreentype p {
  font-size: 16px;
  float: left;
  padding: 8px 0 0 0;
  margin: auto;
}

.diffscreentype button {
  float: right;
}

.topModal.addPlaylilstModal .modal-dialog {
  width: 600px;
  max-width: 600px;
}

.screensButtons>button,
.AddPlaylistsbuttons>button {
  margin: 3px 0px;
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {
  .nocreatenew .AddPlaylistsbuttons {
    width: auto;
    text-align: center;
  }

  .section-dashboard-screens .row.screens {
    overflow: auto;
    height: auto;
  }

  .AddPlaylistsbuttons>button {
    float: none;
  }

  .AddPlaylistsbuttons>.buttonSeperator {
    float: none;
    margin: 10px auto;
  }

  .modal-body {
    padding: 10px;
    overflow: hidden;
  }

}

.add-screens .group-wrapper {
  max-height: calc(100vh - 255px);
  overflow-y: auto;
}

.notEmptyPlaylist {
  max-height: 125px;
  overflow-y: auto;
}


.inner {
  margin: 5px 0 10px 0;
}

.screengroup .inner {
  width: 48%;
  float: left;
  margin: 5px 1% 20px 0;
}

.notEmptyPlaylist-label {
  padding: 5px;
  background: #333;
}

.screengroup .inner:nth-of-type(even) .drop-down-menu_EMFQP {
    left: -95px;
}

.screengroup .inner ._loading_overlay_wrapper {
    width: 150px;
    margin-left: 130px;
}
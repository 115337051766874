#logo {
  margin: 15px 0 0 0;
  display: inline;
}

.logo {
  top: 20px;
  position: fixed;
  left: 8px;
  width: 38px;
}

.menutitle {
  font-size: 16px;
  position: relative;
  top: -2px;
  left: 55px;
  width: 155px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 20px 0 10px 0;
}

#sidebar .menutitle {
  left: -210px;
}

#sidebar.active .menutitle {
  left: 55px;
}

#dismiss {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: #1f1f1f;
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: none;
}

#sidebar.active #dismiss {
  display: inline;
}

#sidebarCollapse {
  right: auto;
  left: 2px;
  position: fixed;
  top: 58px;
  outline: 0px;
  background: transparent;
  padding: 0;
  margin: 0;
  width: 45px;
  height: 40px;
  border: 0;
  z-index: 9;
}

.menuitems ul {
  padding: 20px 0;
  margin: 80px 0 0 0;
  position: absolute;
  top: -40px;
  left: 35px;
  display: inline;
  overflow: auto;
  height: calc(100vh - 100px);
  list-style: none;
  width: calc(100% - 35px);
}

.rolemenu {
  position: relative;
}

.menuitems ul li a,
.rolemenu a,
.rolemenu a:focus,
.rolemenu a:hover {
  font-size: 13px;
  display: block;
  border-bottom: 1px solid #1f1f1f;
  color: #999;
  padding: 10px 10px;
}

.menuitems a:hover,
.menuitems a:focus,
.rolemenu a:focus,
.rolemenu a:hover {
  background: #333;
}

.menuitems ul li ul {
  display: none;
}

.menuitems ul li ul.active {
  display: block;
}

.rolemenu a.rolemenuItems {
  color: #999;
  border: none;
  padding: 8px;
}

.logout {
  position: absolute;
  bottom: 10px;
  left: 22px;
  cursor: pointer;
}

.logoutModal {
  display: block;
  top: 20px;
  left: calc(50% - 300px);
  position: fixed;
  z-index: 19999;
}

.logoutModalInner {
  background-color: #f2f2f2;
  margin: auto;
  padding: 40px 15px;
  border: 1px solid #888;
  width: 600px;
  text-align: center;
  border-radius: 0;
  color: #111;
}

.logoutModalInner p {
  margin-bottom: 25px;
  font-size: 16px;
}

.logoutModalInner button {
  background: #f74866;
  color: #fff;
  transition: all 0.4s ease-out;
  border-radius: 2px;
  margin-right: 10px;
  padding: 10px 12px 10px 12px;
  font-size: 14px;
  font-weight: normal;
  min-width: 135px;
}

.backdrop {
  position: fixed;
  z-index: 19990;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}

.dropdown-menu.show {
  border: none;
}
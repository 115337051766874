.form-item {
    width: 48%;
    margin: 0 2% 20px 0;
    float: left;
}

.form-item input {
    background: #1f1f1f !important;
    color: #666;
    min-height: 32px;
    border-radius: 2px;
    font-weight: 100;
    padding: 2px 8px;
    border: 0;
    line-height: inherit;
    font-size: 13px;
    margin: 0;
    width: 100%;
}

.Device-Configuration-inner {
    overflow: auto;
    overflow-x: hidden;
    height: calc(100vh - 222px);
    display: inline-block;
    width: 100%;
    margin: 10px 0 0 0;
}

.form-item label {
    display: table;
    margin-top: 15px;
    color: #ccc;
}

.form-item textarea {
    resize: none;
    min-height: 30px;
    background: rgba(0, 0, 0, .4);
    border-radius: 3px;
    border: 1px solid #555;
    color: #eee;
    width: 220px;
    font-size: 16px;
    text-align: center;
    line-height: 24px;
    height: 140px;
}

.cunt_num {
    background: #333;
    padding: 5px 6px;
    border-radius: 3px;
    margin-right: 3px;
}

p.limit_note {
    margin-top: 0;
}

#charcunt {
    margin: 8px 0 0 0;
    display: none;
}

.form-item.select-theme {
    display: table;
    width: 100%;
}

.form-item.message {
    display: table;
    width: 48%;
    float: left;
}

.fieldGroup-100 {
    display: inherit;
    height: 100%;
}

.theme-image {
    float: left;
    padding: 10px;
    margin: 10px;
    border: 2px solid #000;
}

.theme-image input {
    display: none;
}

.deviceTheme {
    display: none;
}

.form-item.select-theme label {
    display: table;
}

.selected {
    border: 2px solid #fb6e52;
}

.select select {
    background: #1f1f1f !important;
    color: #666;
    -webkit-appearance: auto;
    border: 0;
    font-size: 13px;
    padding: 6px;
}

.groups-heading-container h2 {
    float: left;
}

.main-action-buttons {
    float: right;
}

.message {
    display: table;
}

.form-item select {
    font-size: 13px !important;
    -webkit-appearance: auto !important;
}

.form-item select:not([size]):not([multiple]) {
    height: calc(1.9rem + 2px);
    background: #222;
    border: 0;
    color: #ccc;
    border-radius: 2px;
    line-height: inherit;
    font-size: 13px;
    padding: 6px 8px;
    width: 100%;
}

.devicenodetheme input {
    display: none;
}

#MultiDeviceForm .message {
    float: none;
}

.mainActionButtons {
    float: right;
}

.offlinedevices-wrap .mainActionButtons .addDevices {
    left: auto;
    bottom: auto;
}


#MultiDeviceForm .Device-Configuration-inner .message {
    float: none !important;
    clear: left;
}

button.addDevices {
    position: relative;
    left: 1000px;
    bottom: auto;
}

.submit-button {
    color: #fff;
    background-color: #f74866;
    border: 0;
    border-radius: 2px;
    padding: 10px 12px 10px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    margin-right: 5px;
}

.cancel-btn {
    border: none;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    font-weight: 100;
    cursor: pointer;
    border-radius: 2px;
    padding: 10px 12px;
    text-align: center;
    background: #333;
    opacity: 1;
    margin: 0;
}

.offlinedevices-wrap .display-devices-off.edit-fld {
    margin: 15px 0 0 0;
    float: left;
}

.bmtextarea {
    clear: both !important;
}

.banner-colors {
    width: 90% !important;
    margin-left: 1% !important;
}

.margin-color-picker {
    top: 5px;
    height: 26px !important;
}
#login #logo {
  fill: #fff;
  padding: 0;
  margin: 0;
}

#login #logo img {
  width: 100px;
}

#login p {
    margin-top: 10px;
}

#login .abc {
border-bottom: 0px solid #ccc;
width: 163%;
margin-top: -51px;
}

#login .divpara {
  position: relative;
  width: 70%;
  display: inline-block;
}

#login .divbtn {
  width: 30%;
  margin-right: 5%;
  display: inline;
}

#login .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.5);
}

#login .hidden {
  display: none;
}


#login .top-heading {
  color: #fff;
  font-size: 1.7rem;
  margin-top: 12px;
  margin-bottom: -43px;
  font-weight: 300;
  width:430px;
}

#login section {
  margin-left: 64px;
  margin-top: 45px;
  width: 224px;

  height: 521px;
}

#login h1 {
  font-size: 27px;
  font-weight: normal;
  padding-bottom: 25px;
  padding-top: 82px;
}

#login .copy {
  color: #666;
  line-height: 1.5;
  margin-bottom: 42px;
}

#login .entry-item {
    margin-top: 30px;
}

#login a {
  margin-right: 3px;
  font-size: 12px;
  color: #d63a5b;
  text-decoration: none;
  padding-bottom: 1px;
}

#login .password-label a {
  left: 348px;
  margin-top: 40px;
}

#login .create {
    font-size: 12px;
    position: relative;
    bottom: -85px;
    color: #999;
    width: 170%;
}

#login .create a {
  float:left;
  border-bottom: 1px solid #d63a5b;
}

#login input {
  font-size: 13px;
  color: #fff !important;
  border: none;
  border-bottom: 1px solid #ccc;
  background: transparent !important;
  width: 161%;
  padding-bottom: 4px;
  box-shadow: none;
}

#login input:focus {
  outline: none;
  background: transparent !important;
}

input:placeholder {
  color: #999;
}

#login button,
#login select {
  background-color: #ff6f88;
  color: #fff;
}

#login button {
  border-color: transparent;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 276px;
  margin-right: 20px;
  margin-top: 60px;
  min-height: 36px;
  min-width: 90px;
  padding: 8px 10px;
}

#login button:hover {
  color: #fff;
  background-color: #000;
  border: 1px solid #fff;
  transition: background-color 200ms ease all;
  -webkit-transition: background-color 200ms ease;
}

#login button:focus,
#login a:focus {
  outline: none;
}

#login select {
  width: 100%;
  height: 25px;
}

#login label {
  display: none;
}

#login .error p {
  color: #d63a5b;
}

#login #password,
#login #logonIdentifier {
  background: transparent !important;
  margin-top: 19px;

}

#login .credit {
  color: transparent;
  text-indent: -10000px;
}

#login a#forgotPassword {
  color: #d63a5b;
  position: fixed;
  border-bottom: 1px solid;

}

#login #deviceDetect {
color: grey;
display: block;
width: 330px;
font-size: 12px;
bottom: 4%;
position: relative;

}

#login .rememberMe {
  display: none;
}

#login #no_cookie{ display:none; }

.entry-item input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999;
  opacity: 1; /* Firefox */
}

.entry-item input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999;
}

.entry-item input::-ms-input-placeholder { /* Microsoft Edge */
  color: #999;
}

#login .bottom-heading {
  color: #fff;
  font-size: 27px;
  font-weight: 300;
  margin-bottom: 5px;
  margin-top: 17px;
}



.cookiealert.show {
  opacity: 1;
  transform: translateY(0%);
  transition-delay: 1000ms;
  padding-top: 0px;
  border-bottom-width: 30px;
  font-size: 0.79rem;
  margin-top: 1rem;
  padding-top: 12px;
  width: 100%;
  display: inline-flex !important; 
  height: 70px;
}

.cookiealert.show a {
  color: #fff;
  text-decoration: underline;
}

.cookiealert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  border-radius: 0;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: #ecf0f1;
  background: #d63a5b;
}
.divpara {
  position: relative;
  width: 70%;
  display: inline-block;
}

.divbtn {
  
  width: 30%;
  margin-right: 5%;
  display: inline;
}

.cookiealert .acceptcookies {
  margin-left: 10px;
  margin-top: 4px !important;
  vertical-align: baseline;
  background-color: #292727;
  font-size: 12px;
  float: right;
}
.overlaylogin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.5);
}
.divpara p {
  float: left;
  margin: 0;
  padding: 5px 0 0 20px;
  text-align: left;
}
.hidden {
  display: none;
}
@-webkit-keyframes autofill {
  0%,100% {
      color: #666;
      background: transparent;
  }
}

#login input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  box-shadow: 0 0 0 30px black inset !important;
  -webkit-text-fill-color: #999;
  -webkit-animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: autofill;
}
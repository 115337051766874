.tui-image-editor-header,
li.tie-btn-delete,
li.tie-btn-deleteAll,
li>div.tui-image-editor-icpartition,
ul.tui-image-editor-submenu-item li.tui-image-editor-submenu-align,
ul.tui-image-editor-help-menu li.tie-btn-hand,
ul.tui-image-editor-submenu-item li.tui-image-editor-partition,
ul.tui-image-editor-help-menu li.tie-btn-zoomIn,
ul.tui-image-editor-help-menu li.tie-btn-zoomOut,
ul.tui-image-editor-help-menu li.tie-btn-history,
div.tie-filter-multiply-color,
div.tie-filter-blend-color {
  display: none !important;
}

ul.tui-colorpicker-clearfix li input {
  min-height: 17px !important;
  width: 17px !important;
  cursor: pointer !important;
}

ul.tui-colorpicker-clearfix li {
  float: left !important;
}

.media-messages-content {
  float: left;
  width: 28%;
}

.media-header-right {
  float: right;
}

.addUserMessages .media-messages-form {
  float: right;
  width: 50%;
  overflow-y: scroll;
  position: relative;
  left: 20px;
  bottom: 20px;
  height: calc(100vh - 130px);
}

.addUserMessages #media_title_field {
  width: 95% !important;
}

.addUserMessages p.sub_heading {
  font-size: 13px;
  color: #999;
}

.page-header {
  margin: 0;
}

.titles_section {
  width: 50%;
}

.buttons_section {
  position: relative;
  left: 20px;
  bottom: 35px;
}

#reset_button {
  margin-right: 10px;
}

.addUserMessages #title_div {
  margin-bottom: 2%;
}

.actions_button {
  border-color: #d62d51;
  color: #fff;
  background-color: #f74866;
  border: 0;
  border-radius: 2px;
  padding: 10px 12px 10px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  cursor: pointer;
}

.addUserMessages .library-left-section {
  width: unset !important;
  height: calc(100vh - 150px);
}

.library-left-section li.react-tabs__tab {
  width: 32%;
}

.addUserMessages .react-tabs__tab-panel {
  width: 98% !important;
  height: calc(98.5vh - 185px) !important;
}

.imageSection {
  float: left;
  width: 22%;
  background: #1f1f1f;
}

.imagesResult {
  height: calc(100vh - 230px);
  overflow-y: scroll;
}

.imageSectionHeader {
  margin: 0;
  font-size: 14px;
  color: #ccc;
  list-style: none;
  padding: 1px 5px;
  background: #111;
  text-transform: capitalize;
}

.addUserMessages .imageBox {
  margin: 15px;
}

.addUserMessages .imageDate {
  margin-top: 2px;
  margin-bottom: 2px;
}

.addUserMessages .imageBox img {
  cursor: pointer;
}

._loading_overlay_overlay {
  z-index: 9997 !important;
  position: fixed !important;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button.apply label,
.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-item .tui-image-editor-button.cancel label {
  vertical-align: unset !important;
}

.addUserMessages ul.pagination li.previous.disabled {
  display: none;
}

.addUserMessages ul.pagination li.next.disabled {
  display: none;
}

.addUserMessages ul.pagination {
  margin: 15px 5px 15px auto;
  list-style: none;
  outline: none;
  float: right;
}

.addUserMessages ul.pagination>li>a {
  border: unset !important;
  padding: 5px 6px !important;
}

.addUserMessages p.imagesTitle {
  margin: 0;
  padding: 10px;
}

.addUserMessages .media-header-left {
  width: 75%;
  display: table;
}

.tui-image-editor-container.bottom {
  width: 36vw !important;
}
.page-access-denied h2 {
  font-size: 200px !important;
  color: #333;
  font-weight: 600;
}

.page-access-denied h4 {
  color: #999;
  font-size: 30px;
}

.page-access-denied {
  text-align: center;
  margin-top: 13%;
}


/*styles for multi unit configuration */

.multiconfig-device {
    position: relative;
    opacity: .7;
    display: inline-block;
    padding: 2px 32px 2px 10px;
    border: 1px solid #cadae6;
    border-radius: 20px;
    vertical-align: middle;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 10px;
    color: #333;
    line-height: 24px;
    background-size: 18px !important;
    background-color: #ccc !important;
    font-weight: 100;
    height: auto;
    width: auto;
}
.display-devices-off.edit-fld {
    margin: 15px 0 0 0;
}
div#edit-offline-devices-desc {
    margin-top: 20px;
    font-size: 13px;
}
.offlinedevicesWrap .addDevices {
    position: relative;
    left: 0;
    bottom: 20px;
}

.offlinedevices-wrap .mainActionButtons .multiform_cancel {
    background: #333;
    margin: 0 0 0 10px;
}



.library-heading {
    display: table;
    width: 100%;
}

.groups-heading-container h2 {
    margin: 0 0 5px 0;
    font-size: 18px;
}

.library-left-section {
    float: left;
    width: 30%;
}

.library-right-section {
    float: left;
    width: 69%;
    margin: 0 0 0 0.5%;
}

.library-inner {
    display: table;
    width: 100%;
}

#Dashboard_SubTitle {
    color: #999;
}

.react-tabs__tab-panel {
    width: 100% !important;
    height: calc(98.5vh - 185px);
    overflow: auto;
    background: #222;
    color: #fff;
}

.page-media .react-tabs__tab--selected {
    background: #222 !important;
    color: #fff;
    border-bottom: 0 !important;
    border-radius: 2px 2px 0 0;
    padding: 8px 20px;
    border-color: #222;
}

.page-media .react-tabs__tab {
    border: 0;
}

.page-media .react-tabs__tab:hover {
    background: #222 !important;
}

.library-page .library-heading {
    margin: 0 0 10px 0;
}

.library-page nav.pagination {
    float: right;
}

.library-page nav.pagination ul li a {
    padding: 3px 7px;
    margin: 0;
}

.react-tabs__tab {
    background: #111 !important;
    padding: 10px !important;
    color: #999;
    white-space: nowrap;
    width: 110px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
    margin: 0 2px 0 0 !important;
}

.page-media .views-exposed-widgets,
.page-node-add-media .views-exposed-widgets {
    display: table;
    width: 100%;
    background: #1f1f1f;
    padding: 5px 10px 0px 10px;
}

.page-media .form-item-sort-by label.control-label,
.page-node-add-media .form-item-sort-by label.control-label {
    float: left;
    margin: 13px 5px 7px 15px;
    color: #999;
}

.page-media .lbr-folder-structure-tabs ul.tabs,
.page-node-add-media .lbr-folder-structure-tabs ul.tabs {
    display: flex;
    height: auto;
    list-style: none;
    margin: 0 !important;
    padding: 0;
}

.page-media .lbr-folder-structure-content,
.page-node-add-media .lbr-folder-structure-content {
    background: #222;
    margin: 10px 0 0 0;
    width: 100%;
    padding: 10px 5px;
}

.library-item-title a {
    font-size: 13px;
}

.page-media .ui-layout-resizer,
.page-node-add-media .ui-layout-resizer {
    background: #707d90;
    width: 2px !important;
    border-width: 0;
    float: left;
    height: 500px;
    min-height: 100%;
}

.page-media .headContainer>*,
.page-node-add-media .headContainer>* {
    float: left;
    display: inline-block;
}

.page-media .headContainer h2,
.page-node-add-media .headContainer h2 {
    margin-top: 10px;
}

.page-media .headContainer h3,
.page-node-add-media .headContainer h3 {
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
}

.page-media .lib-manage-tools div {
    float: left;
    margin: 0;
}

.page-media .blur {
    opacity: 0.3;
}

.blur div:hover,
.blur div a:hover,
.page-media .lib-manage-tools input:hover,
.page-media .playlist-upload input:hover,
.blur p:hover,
.page-media .lib-manage-tools .blur:hover {
    cursor: default !important;
    color: inherit;
}

.page-media .lib-new-folder:hover,
.page-media .lib-rename-folder:hover,
.page-media .lib-delete-folder:hover,
.dimmed-div:hover,
.folder-btns-wrapper-right:hover {
    opacity: 0.6;
    cursor: pointer !important;
}

.upload-to-library .blur a {
    opacity: 0.3;
}

.upload-to-library .blur a:hover {
    cursor: not-allowed !important;
}


.page-media .views-exposed-form label,
.page-media .views-exposed-form .views-widget {
    float: left;
}

.page-media .views-exposed-form .form-control {
    background: #333;
    float: left;
    color: #999;
}

.page-media .views-exposed-form label {
    padding: 10px 10px 0 10px;
    color: #999;
}

#edit-sort-by {
    float: left;
    width: 120px;
}

.page-media .views-exposed-widget.views-widget-sort-by,
.page-media #edit-type-1-wrapper {
    float: right;
    margin: 0 0 0 10px;
}

.page-media div.lib-upload,
.page-media div.lib-del,
.page-media div.lib-copy {
    background: none;
    padding: 0;
    text-align: center;
}

.page-media div.lib-upload:before,
.page-media div.lib-del:before,
.page-media div.lib-copy:before {
    font-family: "FontAwesome", sans-serif;
    display: block;
    font-size: 24px;
    height: 25px
}

.page-media div.lib-upload input,
div.lib-del input,
.page-media div.lib-copy input,
.page-media .lib-rename input,
.page-media .lib-move input {
    width: auto;
    max-width: 60px;
    text-overflow: ellipsis;
    overflow: hidden
}

.page-media .lib-new-folder,
.page-media .lib-rename-folder,
.page-media .lib-delete-folder {
    max-width: 55px;
    position: relative;
    text-align: center;
    margin: 10px 6px !important;
}

.lib-move p,
.lib-rename-folder p,
.lib-delete-folder p,
.lib-new-folder p {
    padding: 7px 0 0;
    text-align: center;
    font-weight: 700;
    font-size: 11px
}

.page-media .modal-body {
    display: table;
    width: 100%
}

.page-media .modal-body h3 {
    margin: 0;
    display: inline
}

.page-media .modal-header h3 {
    float: left;
    margin: 0
}

.page-media #Rename-Folder-Modal .modal-body .form-control {
    border: 1px solid #ccc;
    background: #fff;
    margin: 0 0 10px;
    padding: 25px;
    color: #333
}

.page-media #Rename-Folder-Modal .modal-body {
    padding: 0 15px 15px
}

.page-media .lib-upload a {
    line-height: 24px;
    padding: 33px 0 0
}

.page-media .lib-rename span,
.page-media .lib-del span,
.page-media .lib-copy span,
.page-media .lib-move span,
.page-media .lib-upload span {
    width: 25px;
    height: 25px;
    display: table;
    margin: 10px auto 0 auto;
    background: url(../Assets/mylib_icons.png) no-repeat;
}

.page-media .lib-new-folder span,
.page-media .lib-rename-folder span,
.page-media .lib-delete-folder span {
    width: 30px;
    height: 25px;
    display: table;
    margin: 0 auto;
    background: url(../Assets/library_management_icons_2.png) no-repeat
}

.page-media .lib-new-folder span {
    background-position: 4px 4px
}

.page-media .lib-rename-folder span {
    background-position: -49px 4px
}

.page-media .lib-delete-folder span {
    background-position: -102px 4px
}

.lib-rename,
.lib-del,
.lib-copy,
.lib-move,
.lib-upload {
    line-height: 26px;
    font-size: 11px;
    font-weight: 700
}

.page-media .lib-rename span {
    background: url(../Assets/library_management_icons.png) -55px 2px no-repeat
}

.page-media .lib-del span {
    background-position: 0 -40px
}

.page-media .lib-copy span {
    background-position: -2px -86px
}

.page-media .lib-move span {
    background-position: 0 -128px
}

.page-media .lib-upload span {
    background-position: 0 2px
}

.page-media .lib-manage-tools input {
    text-transform: capitalize;
    font-size: 11px
}

.page-media .lib-new-folder input,
.lib-rename-folder input,
.lib-delete-folder input {
    width: 50px;
    white-space: pre-line;
    text-align: center;
    position: absolute;
    top: 24px
}

.page-media .lib-manage-tools input,
.page-media .playlist-upload input {
    border: 0;
    background: none;
    white-space: pre-line;
    cursor: pointer;
    color: #fff;
    font-size: 11px
}

.folder-btns-wrapper-right input {
    padding: 5px;
    display: table;
    margin: -5px 0 0
}

.page-media .folder-btns-wrapper-right {
    text-align: center;
    background: none !important;
    margin: 0 0 0 !important;
    width: 90px;
    height: 70px;
    border-right: 3px solid #000;
    padding: 0 5px 0 0;
}

.page-media span.new-messages-icon {
    background: url(../Assets/contentSourceList.png) center -598px no-repeat;
    width: 25px;
    height: 17px;
    display: table;
    margin: 10px auto 5px auto;
}

.page-media .lib-manage-tools div:last-child {
    margin: 0 5px 5px 5px;
}

.page-media .lib-manage-tools label {
    display: block;
    margin-bottom: 3px;
    margin-top: 10px;
}

.page-media .lib-manage-tools a {
    color: #fff;
    font-weight: 700;
    font-size: 11px;
}

.page-media .glyphicon-spin {
    display: none;
}

.lib-new-usermessage a {
    padding: 30px 0 0;
}

.page-media .lib-manage-tools .lib-feature-tools div {
    float: left;
    cursor: pointer;
    margin: 0 12px;
}

.page-media .lib-manage-tools .lib-feature-tools div p {
    padding: 0;
    margin: 0;
}

.folder-btns-wrapper {
    margin: 0 !important;
}

.page-media .pagination .glyphicon-spin,
.page-media .btn-success .glyphicon-spin {
    display: inline-block;
}

.view-empty p {
    padding: 15px 15px 10px 15px;
}

.admin-message-show .view-empty p {
    padding: 0 0 0 15px;
}

.page-media .lib-manage-tools a:hover {
    text-decoration: none;
    color: #ccc;
}

.page-media .mpd-separator {
    border-bottom: 1px solid #5b708d;
}

.page-media .lib-rename-folder {
    background-position: -43px 5px !important;
}

.page-media .folder-btns-wrapper {
    border-left: 3px solid #000;
    border-right: 3px solid #000;
    background: none !important;
    height: 70px;
    padding: 0 !important;
}

.page-media .lib-delete-folder {
    background-position: -97px 5px !important;
}

.page-media .lib-manage-tools {
    float: right;
    margin-top: 0;
    margin-bottom: 0;
    width: auto;
    display: flex
}

.page-media #filesTools {
    display: table;
    background: #1f1f1f;
    width: 100%;
    margin: 0 0 4px 0;
    font-size: 11px;
}

.page-media .head-title h2 {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: normal;
}

.page-media .head-title h3 {
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
}

.page-media .ajaxLoader {
    float: left;
    padding-left: 2px;
}

.page-media input[type="button"] {
    padding: 3px 1px 1px 1px;
    margin-left: -11px;
    left: 2px;
    font-weight: normal;
    float: left;
}

.page-media input[type="button"]:hover {
    color: #999
}

.page-media .lib-manage-tools .library-last-selection a {
    line-height: 23px;
    text-indent: -9999px;
    font-size: 0;
    margin: 0 10px 0 0;
    width: 35px;
    height: 29px;
    display: inline-block;
    background: url(../Assets/Edit-Floor-Info-icons.png) no-repeat;
    background-position: left -58px;
}

#ThumbviewWrapper.active {
    background-position: left 1px;
}

.page-media .lib-listview-normal,
.page-node-add-media .lib-listview-normal {
    background-position: left -89px !important;
}

.page-media .views-exposed-widgets label {
    float: left;
    color: #999;
    margin: 10px 10px 0 0;
}

.page-media .views-exposed-widgets .views-widget {
    float: left;
}

.page-media .views-exposed-widgets .views-exposed-widget {
    width: auto;
}

.page-media .views-exposed-widgets #edit-filetype-wrapper {
    margin: 0 0 0 20px;
}

.page-media .views-exposed-widgets input,
.page-media .views-exposed-widgets select {
    background: #333 !important;
}

.page-media li.views-row {
    list-style: none;
    width: 49%;
}

.page-media li.views-row-list {
    list-style: none;
    width: 98%;
}

.page-media .library-item-file img,
.views-field-field-mediaimage-1 img {
    float: left;
    max-width: 100px;
    max-height: 85px;
}

.page-media .library-item-file,
.views-field-field-mediaimage-1 {
    float: left;
    margin: 0 10px 0 0;
    width: 120px;
}

.page-media .library-item-date,
.library-item-size,
.views-field-timestamp-1,
.views-field-filesize-1 {
    color: #ccc;
    font-size: 12px;
}

.page-media .view-content ul li {
    float: left;
    margin: 0 1% 5px 0;
    list-style: none;
    padding: 10px 25px 10px 10px;
    background: #292929;
    height: 100px;
}

.page-media .view-content .pagination ul li {
    float: inherit;
    margin: 0 1% 5px 0;
    list-style: none;
    padding: 0px 0px 0px 0px;
    background: #292929;
    height: auto;
}

.page-media .library-item-content {
    float: right;
    margin: 27px 0 0 0;
}

.page-media .library-item-date {
    padding: 10px 0;
}

.page-media .view-content {
    background: #1f1f1f;
    display: table;
    padding: 10px;
    width: 100%;
    margin-top: 5px;
}

.page-media .view-content ul {
    margin: 0;
    padding: 0;
}

.item-list {
    height: calc(100vh - 310px);
    overflow: auto;
}

.full-input {
    border: 1px solid #999;
    background: transparent;
    border-radius: 2px;
    color: #333;
    width: 100%;
}

.library-left-section-pop {
    float: left;
    width: 100%;
}

.library-left-section .react-tabs__tab-panel ul {
    background-color: transparent;
    padding: 10px 10px 10px 0px;
}

.library-left-section .react-tabs__tab-panel ul ul {
    padding: 0;
}

.library-upload .full-input {
    padding: 8px;
    background: #fff;
}

.library-upload .react-tabs__tab--selected {
    background: transparent !important;
    border-bottom: 2px solid #333;
    color: #000;
}

.library-upload .react-tabs__tab {
    background: transparent !important;
}

.library-upload .react-tabs__tab-panel {
    background: transparent;
    border-top: 1px solid #ccc;
}

.library-upload input#fileButton {
    background: #fff !important;
}

.library-upload .modal-body {
    padding: 20px 30px;
}

.library-upload .modal-footer {
    border: 0;
    display: table;
}

.library-upload .modal-footer .btn {
    margin: 0 0 10px 20px;
}

.upload-file {
    background: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    margin: 5px 0 0 0;
    color: #333;
}

.library-upload ._loading_overlay_wrapper {
    margin: 10px 0;
    color: #333;
}

.upload-file button {
    margin: 0 5px 0 0;
}

.page-media .lib-listview-normal.active {
    background-position: left -29px !important;
}

.library-item-title {
    color: #fff;
}

.library-delete-popup .modal-footer,
.library-delete-popup .modal-header,
.modal-with-message .modal-header {
    border: 0;
}

.library-delete-popup .modal-body,
.modal-with-message .modal-body {
    font-size: 18px;
}

.delete-options {
    float: right;
}

.library-delete-popup .modal-content {
    border-radius: 0;
    padding: 10px 10px 5px 10px;
}

.modal-with-message .modal-footer {
    display: none;
}

.library-upload .upload-instructions .arrow-cs:after {
    top: -57px;
    content: " ";
    position: absolute;
    display: block;
    border-color: transparent;
    border-style: solid;
    border-bottom-color: #fff;
    border-width: 11px;
}

.library-upload .upload-instructions .arrow-cs {
    position: relative;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-top-width: 0;
    left: 10%;
}

.library-upload .upload-instructions {
    z-index: 1060;
    max-width: 276px;
    padding: 1px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143;
    line-break: auto;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    font-size: 14px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    color: #333;
    position: relative !important;
    left: 45px !important;
    bottom: 5px !important;
}

.library-upload .upload-instructions-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
    color: #333;
}

.library-upload a.more-information {
    color: #333 !important;
}

.library-upload .custom_file_upload {
    background: #fff;
    display: table;
    width: 100%;
    padding: 5px 10px;
    border: 1px solid #ccc;
}

.library-upload .custom_file_upload button.file_button {
    display: inline;
    width: auto;
    background: #eee;
    float: left !important;
    margin: 0;
    padding: 5px;
    border: 1px solid #999;
    font-size: 13px;
    left: 2px;
    font-weight: normal;
    text-align: center;
    opacity: 1;
}

.library-upload .custom_file_upload label {
    display: inline;
    float: left;
    width: auto !important;
    margin: 5px 0 0 10px;
    padding: 0 0 3px 0;
    font-size: 14px;
}

div.media_file_upload {
    margin-top: 20px !important;
}

.file_upload_loader .upload-instructions {
    position: fixed;
}

.library-inner .react-tabs .react-tabs__tab-panel {
    height: calc(100vh - 183px) !important;
    overflow: auto;
}

.library-left-section-pop .react-tabs .react-tabs__tab-panel {
    min-height: 200px !important;
    max-height: 300px !important;
    overflow: auto;
}

.add-folder input.full-input {
    background: #fff;
    padding: 10px;
    margin: 5px 0 0 0;
}

.add-folder .modal-body {
    padding: 20px;
}

div.media_tabs li.react-tabs__tab--selected {
    color: #111 !important;
}

.library-item-file .img-content-block.playicon {
    position: relative;
    width: 140px;
    height: 88px;
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {
    .page-media .lib-manage-tools .lib-feature-tools div {
        margin: 0 7px;
    }

    .item-list {
        height: calc(100vh - 420px);
    }

    .page-media li.views-row {
        list-style: none;
        width: 100%;
    }

    .page-media .view-content ul li {
        margin: 8px 1% 5px 0;
    }

    .library-page.page-media .views-exposed-widget.views-widget-sort-by {
        float: left;
        margin: -11px 0 0 10px;
    }
}

.library-upload div.media_tooltip {
    background: none !important;
}

.library-upload .__react_component_tooltip.show {
    opacity: 1 !important;
}

.library-upload .__react_component_tooltip::before,
.library-upload .__react_component_tooltip::after {
    display: none !important;
}
.library-left-section ul li { padding-left:5px; }
.note-text {
    margin-top: 5px;
    font-size: 12px;
  }
  .note-text span {
    font-weight: bold;
  }
.addDeviceForm label {
  margin-bottom: 5px;
}

.deviceFieldLeft {
  float: left;
  width: 48%;
}

.deviceFieldRight {
  float: right;
  width: 48%;
  margin-right: 10px;
}

.addDeviceWrapper {
  overflow: auto;
  height: calc(100vh - 180px);
}

.fieldGroup-50 {
  min-height: 70px;
}

input.deviceTheme {
  margin: 50px 25px 50px 0;
}

textarea#infoMsg {
  resize: none;
  width: 220px;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  height: 140px;
  min-height: 30px;
  background: rgba(0,0,0,.4);
  border-radius: 3px;
  border: 1px solid #555;
  color: #eee;
}

button.addDevices {
  position: relative;
  left: 95%;
  bottom: 20px;
}

.register input {
  border: none;
  border-bottom: 1px solid #999;
  background: transparent;
  width: 100%;
  color: #fff;
  padding-top: 5px;
  /* to hide red error border from firefox*/
  box-shadow: none;
}

.register input[type=text]:focus,
.register input[type=password]:focus,
.register div:focus {
  outline: none;
}

.register input:placeholder {
  color: #999;
}

.register button,
select {
  background: #ccc;
  color: #000;
  cursor: pointer;
}

.register button {
  padding: 10px;
  margin-top: 20px;
  margin-right: 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  min-width: 90px;
  border: 1px solid transparent;
  /* removes safari border */
}

.register button:hover {
  color: #fff;
  background-color: #000;
  border: 1px solid #fff;
  transition: background-color 200ms ease all;
  -webkit-transition: background-color 200ms ease;
}
.register button#continue,
 button#email_ver_but_verify {
  color: #fff;
  background-color: #d63a5b;;
  border: 1px solid #d63a5b;
}

.register button#continue:hover,
  button#email_ver_but_verify:hover {
    border: 1px solid #ff6f88;
    background:  #ff6f88;
    color: #fff;
    transition: background-color 200ms ease all;
    -webkit-transition: background-color 200ms ease;
  }

.register select {
  width: 100%;
}


.register .error p,
.register .verificationErrorText.error,
.register a {
  color: #d63a5b;
}

.register .error.itemLevel {
  display: none;
}

.register .error.itemLevel.show {
  display: block;
  /* padding-top: 14px; */
}

.register ul {
  padding: 0px;
  display: block;
  flex-direction: column;
}

.register li {
  list-style: none;
  padding: 10px 0 10px;
}

/*  ul li:first-child {
  order: 2;
}

email */
/* ul li:first-child {
    order: 1;
  } */

/* password */
/* ul li:nth-child(2) {
    order: 2;
  } */

/* confirm password */
/* ul li:nth-child(3) {
    order: 3;
  } */

/* first name */
/* ul li:nth-child(6) {
    order: 4;
  } */

/* last name */
/* ul li:nth-child(4) {
    order: 5;
  } */

/* phone */
/* ul li:nth-child(5) {
    order: 6;
  } */

/* country  */
/* ul li:nth-child(7) {
    order: 7;
  } */

/* company  */
/* ul li:nth-child(8) {
    order: 8;
  } */

  .register .buttons {
  width: 280px;
}

.register h1 {
  font-weight: 300;
  font-size: 1.8rem;
}

.register .buttons.verify {
  margin-left: 0px;
}
.register input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px black inset !important;
  box-shadow: 0 0 0 30px black inset !important;
  -webkit-text-fill-color: #999;
  -webkit-animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: autofill;
}
.register .reg-wrapper {
  width: 700px;
}

.register .reg-wrapper .reg-copy {
  float: left;
  width: 35%;
  margin-top: 30px;
  line-height: 1.6em;
  font-weight: 300;
}

.register #api {
  margin-left: 15%;
  margin-top: -15px;
  width: 42%;
  float: left;
}


/* POC */
.register .cp-form-cover li:nth-child(12),
.register .cp-form-cover li:nth-child(14) {
  float: left;
  width: 70px;
  clear: both;
}

.register .cp-form-cover ul {
  display: inline-block !important;
}

.register .cp-form-cover li:nth-child(13),
.register .cp-form-cover li:nth-child(15) {
  float: right;
  display: inline-block;
  width: 190px;
  position: relative;
}

.register .cp-form-cover li:nth-child(12) {
  clear: both;
}

.register  .cp_mobile_note {
  clear: both;
}

.register .cp_mobile_note {
  clear: both;
  font-size: 11px;
  color: #3d3d3b;
}

.register  .cp_checkbox {
  display: inline-block;
  color: #d63a5b;
}

.register  .cp_checkbox p {
  margin: 10px 0;
}

.register .cp_mobile_instruction {
  position: absolute;
  width: 170px;
  left: 200px;
  top: -14px;
  font-size: 11px;
  color: #3d3d3b;
}

.register .cp-form-cover {
  /* display: inline-block;
  margin-left: 60px;
  margin-bottom: 20px; */
}

.register label[for=extension_AgreeToTerms_1],
.register label[for=extension_AgreeToPrivacyPolicy_1] {
  display: block;
  color: #d63a5b;
  cursor: pointer;
  float: left;
  margin-left: 8px;
  padding-right: 4px;
  border: none;
}

.register input#extension_AgreeToTerms_1,
.register input#extension_AgreeToPrivacyPolicy_1 {
  float: left;
  text-align: left;
  width: 13px;
  padding: 0;
  margin: 0;
  border: none;
}

.register .cp-form-cover li:nth-child(16) {
  clear: both;
  padding-top: 21px;
}

.register .cp-form-cover li:nth-child(18) .attrEntry,
.register .cp-form-cover li:nth-child(19) {
  display: inline-block;
  margin: 0;
  padding: 0;
  clear: both;
}

.register .reg-wrapper .phone_info {
  float: left;
  width: 245px;
  margin-left: 632px;
  margin-top: 385px;
  line-height: 1.6em;
  font-weight: 300;
  position: absolute;
  font-size: 11px;
  color: #3d3d3b;
}

.register .reg-wrapper .phone_warning {
  float: left;
  width: 280px;
  margin-left: 347px;
  margin-top: 423px;
  line-height: 1.6em;
  font-weight: 300;
  position: absolute;
  font-size: 11px;
  color: #3d3d3b;
}

.register #continue{
  float: right!important;
margin-top: 0px;
margin-right: 72px;

}

.register #cancel {
  margin-top: 5px;
float: left!important;
margin-top: 0px !important;
margin-bottom: 30px;
}

.register div#attributeVerification {
  padding-bottom: 40px;
}

.register .touHelp,
.register .privacyHelp {
  padding-left: 21px;
  font-size: 11px;
  line-height: 20px;
}

.register .phoneHelp {
  position: absolute;
  left: 202px;
  width: 330px;
  top: 50px;
  font-style: italic;
  font-size: 11px;
}

.register .phoneHelp {
  top: 10px;
}

.register #regInfo {
  width: 257px;
  float: left;
}
.register label,
.register .intro p {
  display: none;	
}
#regInfo p {line-height: 22px;}

.register .error {
    color: #d63a5b;
    margin-top: 5px;
    display: block;
}

.register li.select-small {
    float: left;
    clear: left;
    width: 70px;
}

.register li.number-small {
    float: left;
    width: 245px;
    margin-left: 35px;
    margin-top: 20px;
}

.register li.TextBox.position {
    margin: 15px 0;
    clear: both;
    display: list-item;
}

.register .CheckboxMultiSelect input[type=checkbox]:checked:before {
    content: "\f046";
    color: #fff;
    font-size: 16px;
}

.register #edit-account .form-control[disabled], #edit-account input {
    color: #eee;
}

.register .regTitle {
	width: 30%;
}
.register .error ul li {
	padding: 2px;
}
/* Groups Styling */
.group-header {
    margin: 0;
    padding: 12px 15px;
    font-size: 14px;
    display: table;
    width: 100%;
    border-bottom: 2px solid #000;
    text-align: left;
    background: rgb(26 26 26);
}

span.date-updated-screens {
    float: left;
    padding: 2px 0 0 2px;
}

article.group-list-number {
    text-align: left;
}

.group-list-img img {
    width: auto !important;
}

.group-header h3:before {
    content: '\f07b';
    font-family: "FontAwesome", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #f90;
    font-size: 15px;
    margin: 0 7px 0 4px;
    text-decoration: none;
}

.group-header h3 {
    font-size: 14px;
    float: left;
    margin: 0 3px 0 0;
}

.date-updated-screens {
    color: #777;
    font-size: 12px;
}

.group-tag span {
    float: right;
    color: #ccc;
    background: #333;
    padding: 5px;
    border-radius: 3px;
    margin: -5px 0 0 0;
}

.group-list-title {
    color: #ff9900;
    padding: 0 0 5px 0;
}

.group-list-info-label {
    color: #999;
}

li.slide {
    padding: 0;
    margin-top: 2px;
    width: 100%;
    display: table;
}

.group-list-info {
    margin: 0 0 5px 0;
}

ul.group-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    font-family: "FontAwesome", sans-serif;
    font-size: 75px;
    font-weight: normal;
    color: #ccc;
}

.carousel .control-prev.control-arrow:before {
    content: "\f104";
}

.carousel .control-next.control-arrow:before {
    content: "\f105";
}

.carousel .carousel-status {
    position: relative;
    font-size: 13px;
    float: right;
    margin-right: 5%;
}

.carousel .control-next.control-arrow:before,
.carousel .control-prev.control-arrow:before {
    border: 0;
}

.carousel .slider-wrapper {
    width: 90%;
    height: calc(100vh - 260px);
    background: rgba(255, 255, 255, .1);
    margin-top: 1%;
}

.add-screens .carousel .slider-wrapper {
    width: 90%;
    height: calc(100vh - 130px);
    background: rgba(255, 255, 255, .1);
    margin-top: 1%;
}

.carousel.carousel-slider .control-arrow {
    top: -30px;
}

.carousel .control-dots .dot {
    width: 10px;
    height: 10px;
    margin: 0 4px;
}

.carousel .control-dots .dot.selected {
    opacity: 1;
    background: #fc6e51;
    filter: alpha(opacity=100);
}

.carousel .control-dots {
    left: 50%;
}

.add-new-btn-lg,
.btn-success,
.button,
.view-directory-main-screen #edit-submit-directory-list,
#edit-actionassign-screen-action,
#Yes,
#NO,
#LetStart,
#Cancel,
#OK,
#CopyOK,
#MoveOK,
#edit-action-screens,
.btn,
#btnSave,
#SaveAsNew,
#btnSaveAs,
#EditTitleToolTip,
.cd-save,
#TermsCancel,
.page-groupdevicelist .modal-content button#edit-submit,
.submit-remove {
    color: #fff;
    background-color: #f74866;
    border: 0;
    border-radius: 2px;
    padding: 10px 12px 10px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    margin-right: 5px;
}

.btn-danger,
.page-groupdevicelist .modal-content button#edit-submit {
    padding: 10px 12px 10px 12px;
}

.groups-heading-container {
    float: left;
    margin: 0 0 20px 0;
}

.groups-heading-container h2 {
    margin: 0 0 10px 0;
    font-size: 18px;
}

.page-groupdevicelist a.ctools-use-modal.group-screens:before {
    content: "\f040";
    font-family: "FontAwesome", sans-serif;
    visibility: visible;
    font-size: 18px;
    display: table;
    font-weight: 400;
    color: #ccc;
    float: left;
    margin: -2px 7px 0 0;
}


/*   Groups popup Css */

.modal-backdrop.show {
    opacity: .8;
}

.popup-title {
    padding: 15px 15px 5px 15px;
    border-bottom: 1px solid #e5e5e5;
    display: table;
    width: 100%;
}

.popup-title h3 {
    font-size: 16px;
    font-weight: 600;
    float: left;
}

.add-new-btn-lg svg {
    margin-right: 5px;
    margin-top: -2px;
}

.group-tag #create-group:before {
    content: "\f040";
    font-family: "FontAwesome", sans-serif;
    visibility: visible;
    font-size: 18px;
    display: table;
    font-weight: 400;
    color: #ccc;
    float: left;
    margin: -2px 7px 0 0;
}

.group-tag #create-group {
    background: transparent;
    border: 0;
    float: right;
    color: #fff;
}

.add-new-btn-lg {
    float: right;
}

.close {
    font-size: 22px;
    font-weight: 700;
    margin: 0;
    float: right;
    padding: 0;
    line-height: 13px;
}

.form-item-title {
    background: #eee;
    padding: 10px 20px 10px 0px;
    display: table;
    margin: 0;
    width: 100%;
}

.popup-content {
    height: calc(100vh - 210px);
    overflow: auto;
    margin: 0 0 34px 5px;
    position: relative;
    padding: 15px;
}

.popup-form .form-text {
    width: 350px;
    background: #fff;
    border: 1px solid #999;
    padding: 8px;
    border-radius: 2px;
    margin: 0 0 0 5px;
}

.popup-form .form-text:focus {
    outline: 0;
}


.group-item .checkbox {
    float: left;
    width: 150px;
    margin: 10px 10px 10px 0;
}

.group-item input.form-checkbox {
    float: left;
    margin: 1px 5px 0 0;
}

.group-item .views-field-field-mpdid {
    clear: both;
    display: block;
    padding: 5px 0;
}

.form-required-text {
    color: #333;
    margin: 5px 0 10px 0;
    display: table;
}

.group-item {
    float: left;
    width: 145px;
    margin: 0 20px 15px 0;
}


.group-item input.form-checkbox:before {
    content: "\f096";
    font-family: "FontAwesome", sans-serif;
    visibility: visible;
    font-size: 18px;
    font-weight: 400;
    color: #ccc;
    border: 0;
}

.group-item input.form-checkbox:checked:before {
    font-family: "FontAwesome", sans-serif;
    content: "\f046";
    color: #fc6e51;
    visibility: visible;
}

.topModal .modal-dialog {
    width: 980px;
    max-width: 980px;
}

.group-item input[type=checkbox] {
    margin: 1px 7px 0 0;
    visibility: hidden;
}

.group-item input[type='checkbox']:hover {
    cursor: pointer;
}

.group-item .views-field-title {
    font-weight: 100;
    display: block;
    padding: 0px 0 0 5px;
    width: auto;
    max-width: 90%;
    text-overflow: ellipsis;
    font-size: 13px;
    overflow: hidden;
    float: left;
}

.views-field-field-mpdid {
    font-size: 12px;
    font-weight: 100 !important;
    margin: 0 0 5px 0;
    color: #444;
    display: table;
}

.label-field-mpdid {
    float: left;
    margin: 0 2px 0 0;
    color: #666;
}

.pop-up-items {
    display: table;
}

.topModal .modal-header {
    padding: 5px;
    border-bottom: 0;
    margin: 0;
    font-size: 12px;
}

#edit-actions {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

#edit-submit {
    text-align: center;
}

#edit-submit:hover,
#edit-delete:hover {
    color: #fff;
    background-color: #d62d51;
    border-color: #d62d51;
}

.group-wrapper-here {
    display: table;
    height: 145px;
    width: 195px;
    float: left;
    padding: 15px 20px 0 20px;
}

span.groups-label {
    color: #999;
    display: table;
    font-size: 13px;
}

.group-header input {
    margin: 0px 5px 0 0;
    width: 15px;
}

#once-start-date,
#once-end-date,
#rec-start-date,
#rec-end-date {
    color: #999;
    padding: 13px 10px 10px;
    border-radius: 2px;
    border: 0;
    background: url(../../Assets/calendar-icon.png) #363636 122% -15px no-repeat;
    width: 140px;
}

#once-start-time,
#once-end-time,
#rec-start-time,
#rec-end-time {
    color: #999;
    padding: 13px 10px 10px;
    border-radius: 2px;
    border: 0;
    background: url(../../Assets/clock-icon.png) #363636 122% -15px no-repeat;
    width: 140px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;

}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
}

.weekDays-selector input {
    margin: 15px 6px 15px 0;
    width: 15px;
}

.scheduleTypeOnceFields .box {
    padding: 40px 40px 20px 40px;
}

.weekDays-selector {
    text-align: left;
    margin: 0 0 0 40px;
}

/* Add screens page alter */
.add-screens.group-list-main .carousel .slider-wrapper {
    background: transparent;
}

.add-screens.group-list-main .group-name {
    float: left;
    font-size: 18px;
}

.add-screens.group-list-main .group-name span {
    margin-bottom: 5px;
}

.add-screens.group-list-main .thumbs-wrapper {
    display: none;
}

.add-screens.group-list-main .carousel .control-dots {
    text-align: center;
    left: unset;
    bottom: 35px;
}

.add-screens.group-list-main .carousel .carousel-status {
    display: none;
}

.add-screens-page .group-wrapper {
    background: rgba(255, 255, 255, .1);
    float: left;
    width: 100%;
    height: 355px;
}

.add-screens-page .group-wrapper-here {
    float: left;
    margin: 15px 2% 0;
    width: 29%;
    text-align: left;
    background: none;
    padding: 0;
}

.add-screens-page .group-list-img {
    float: left;
    width: 100px;
    margin-right: 10px;
}

.add-screens .carousel .slide img {
    width: 100% !important;
}

.add-screens-page .screen-data {
    float: left;
    width: calc(100% - 110px);
}

.screen-data-checkbox {
    display: inline-block;
    width: 20px;
    vertical-align: top;
}

.screen-data-info {
    display: inline-block;
    width: calc(100% - 20px);
}

.groups-popup .modal-dialog {
    width: 75%;
}

.groups-popup .form-item-title {
    margin: 0 0 10px 0;
}

.group-slider-cs li {
    height: 145px;
    float: left;
    list-style: none;
}

.create-live-content .form-item p {
    margin-bottom: 0px;
}

.create-live-content .form-item .form-item {
    margin-bottom: 0px;
}

.alert-grp-popup li {
    margin-left: 20px;
}

.btn-cancel {
    color: #fff;
    border: 0;
    border-radius: 2px;
    padding: 10px 12px 10px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
    margin-right: 5px;
    background: #222;
}

.group-slider-cs .carousel-root .carousel~.carousel {
    display: none;
}

@media only screen and (min-width: 500px) and (max-width: 1000px) {
    .add-screens-page .group-wrapper-here {
        width: 20% !important;
    }

    .add-screens-page .group-list-img {
        width: 100% !important;
    }

    .group-list-info {
        font-size: 12px;
    }

    .carousel .slide img {
        width: 100% !important;
    }

    .add-screens-page .screen-data {
        width: 100%;
        margin-top: 5px;
    }
}

.no-data {
    display: table;
    width: 100%;
    padding-top: 25px;
}

.pagination-right .pagination ul {
    float: right;
}

.group-slider-cs li,
.add-screens .slider li {
    display: inline;
}

.groups-popup.modal.fade .modal-dialog {
    width: 56%;
}

.main-group-wrapper {
    max-height: calc(100vh - 255px);
    overflow-y: auto;
}
.groups-popup-container input[type=checkbox]:checked:before {
    content: "\f046";
    color: #f90;
    font-size: 18px !important;
}
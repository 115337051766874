.calendardiv {
  height: 360pt;
}

@charset "UTF-8";

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  height: 97%;
  display: flex;
  flex-direction: column;
  margin: 15px 0 0 0;
  align-items: stretch;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit;
}

.rbc-abs-full,
.rbc-row-bg {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: #999;
}

.rbc-off-range-bg {
  background: #111;
}

.rbc-date-cell.rbc-off-range a {
  color: #222;
}

.rbc-header {
  color: #999;
  overflow: hidden;
  flex: 1 0 0%;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  border-bottom: 1px solid #333;
}

.rbc-header+.rbc-header {
  border-left: 1px solid #333;
}

.rbc-rtl .rbc-header+.rbc-header {
  border-left-width: 0;
  border-right: 1px solid #333;
}

.rbc-header>a,
.rbc-header>a:active,
.rbc-header>a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
  margin: 0;
}

.rbc-row-content-scrollable {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  height: 100%;
  overflow-y: scroll;
  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none;
  /* Firefox */
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: #222;
}

.live {
  background: #166916;
}

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: #999;
}

.rbc-toolbar .rbc-toolbar-label {
  flex-grow: 1;
  padding: 0 10px;
  text-align: center;
  color: #eee;
  font-size: 16px;
}

.rbc-toolbar button {
  color: #999;
  display: inline-block;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  background: none;
  background-image: none;
  border: 1px solid #333;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  line-height: normal;
  white-space: nowrap;
  font-size: 13px;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  background-color: #444;
  border-color: #444;
  color: #ccc;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.rbc-toolbar button:focus {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap;
}

.rbc-btn-group>button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group>button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group>button:first-child:not(:last-child) {
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group>button:last-child:not(:first-child) {
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group>button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.rbc-btn-group button+button {
  margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button+button {
  margin-left: 0;
  margin-right: -1px;
}

.rbc-btn-group+.rbc-btn-group,
.rbc-btn-group+button {
  margin-left: 10px;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  border: none;
  box-sizing: border-box;
  box-shadow: none;
  padding: 2px 5px;
  background-color: #333;
  color: #ccc;
  cursor: pointer;
  width: 96%;
  font-size: 12px;
  text-align: left;
  border-radius: 2px !important;
  margin: 0 5px 0 3px;
}

.rbc-slot-selecting .rbc-event,
.rbc-slot-selecting .rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #444;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #444;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;
}

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 4;
  font-weight: bold;
  font-size: 85%;
  height: auto;
  line-height: normal;
}

.rbc-month-view {
  position: relative;
  border: 1px solid #222;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 97%;
  border-radius: 3px;
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%;
}

.rbc-month-row+.rbc-month-row {
  border-top: 1px solid #222;
}

.rbc-date-cell {
  flex: 1 1;
  min-width: 0;
  padding-right: 5px;
  text-align: right;
  color: #666;
  font-size: 12px;
}

.rbc-date-cell.rbc-now {
  font-weight: bold;
}

.rbc-date-cell>a,
.rbc-date-cell>a:active,
.rbc-date-cell>a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;
}

.rbc-day-bg+.rbc-day-bg {
  border-left: 1px solid #333;
}

.rbc-rtl .rbc-day-bg+.rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #333;
}

.rbc-overlay>*+* {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px -10px;
  padding: 2px 10px;
  display: none;
}

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border: 1px solid #333;
  border-spacing: 0;
  border-collapse: collapse;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td {
  padding: 5px 10px;
  vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  padding-left: 15px;
  padding-right: 15px;
  text-transform: lowercase;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
  border-left: 1px solid #333;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
  border-left-width: 0;
  border-right: 1px solid #333;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr+tr {
  border-top: 1px solid #333;
}

.rbc-agenda-view table.rbc-agenda-table thead>tr>th {
  padding: 3px 5px;
  text-align: left;
  border-bottom: 1px solid #333;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead>tr>th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.rbc-time-column .rbc-timeslot-group {
  flex: 1;
}

.rbc-timeslot-group {
  border-bottom: 1px solid #333;
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap;
}

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}

.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}

.rbc-day-slot .rbc-event,
.rbc-day-slot .rbc-background-event {
  border: 1px solid #265985;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}

.rbc-day-slot .rbc-background-event {
  opacity: 0.75;
}

.rbc-day-slot .rbc-event-label {
  flex: none;
  padding-right: 5px;
  width: auto;
}

.rbc-day-slot .rbc-event-content {
  width: 100%;
  flex: 1 1 0;
  word-wrap: break-word;
  line-height: 1;
  height: 100%;
  min-height: 1em;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #222;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid #333;
  z-index: 10;
  margin-right: -1px;
}

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}

.rbc-time-view-resources .rbc-header,
.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0 px;
}

.rbc-time-header-content+.rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 75%;
  width: 100%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: 1px solid #333;
  min-height: 0;
}

.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
}

.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}

.rbc-time-view .rbc-allday-cell+.rbc-allday-cell {
  border-left: 1px solid #333;
}

.rbc-time-view .rbc-allday-events {
  position: relative;
  z-index: 4;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
}

.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #333;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-left: 1px solid #333;
}

.rbc-time-header>.rbc-row:first-child {
  border-bottom: 1px solid #333;
}

.rbc-time-header>.rbc-row.rbc-row-resource {
  border-bottom: 1px solid #333;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;
  border-left: 1px solid #333;
}

.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #333;
}

.rbc-time-header-content>.rbc-row.rbc-row-resource {
  border-bottom: 1px solid #333;
  flex-shrink: 0;
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  border-top: 2px solid #333;
  overflow-y: auto;
  position: relative;
}

.rbc-time-content>.rbc-time-gutter {
  flex: none;
}

.rbc-time-content>*+*>* {
  border-left: 1px solid #333;
}

.rbc-rtl .rbc-time-content>*+*>* {
  border-left-width: 0;
  border-right: 1px solid #333;
}

.rbc-time-content>.rbc-day-slot {
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #74ad31;
  pointer-events: none;
}

/*# sourceMappingURL=react-big-calendar.css.map */

.calendar-popup .topModal .modal-dialog {
  max-width: 80%;
}

.calendar-popup .TypeButtons {
  float: left;
  width: 300px;
  min-height: 300px;
}

.calendar-popup .modal-content {
  background: #fff !important;
}

.calendar-popup .TypeButtons button {
  color: #333;
  margin: 0 10px 10px 10px
}

.calendar-popup .box input {
  background-color: #eee !important;
}

.calendar-popup .modal-footer {
  border: 0;
  display: table;
  width: 100%;
  text-align: center;
  margin: 0 0 20px 0;
}

.calendar-popup.modal.show .modal-dialog {
  width: 80%;
}

.calendar-popup .schedulePlaylistSection {
  margin: 0px auto 15px;
  text-align: left;
}

.calendar-popup .scheduleTypeSectionTitle {
  border: 0;
  color: #333;
  padding: 0;
}

.calendar-popup .scheduleTypeOnceFields {
  max-width: 100% !important;
  margin: 10px auto;
}

.calendar-popup .scheduleTypeOnceFields .box {
  margin: 10px 20px 10px 0;
  padding: 0;
  width: 250px !important;
  float: left;
}

.calendar-popup .scheduleTypeRecurFields {
  max-width: 100%;
}

.calendar-popup .weekDays-selector {
  margin: 0;
  float: left;
}

.calendar-popup #once-start-date,
.calendar-popup #once-end-date,
.calendar-popup #rec-start-date,
.calendar-popup #rec-end-date,
.calendar-popup #rec-start-time,
.calendar-popup #rec-end-time {
  color: #666;
  background-color: #eee !important;
}

.calendar-popup .scheduleTypeRecurFields .dateTimeField {
  width: auto;
  margin: 20px 2% 20px 0;
  float: left;
}

.calendar-popup input[type=checkbox]:before {
  color: #666;
}

.calendar-popup .react-confirm-alert-overlay {
  top: -40%;
  border-radius: 5px;
}

.calendar-popup .TypeButtons button.active {
  color: #fc6e51;
  margin: 0 10px 10px 10px;
}

.delete-schedules {
  background: rgba(0, 0, 0, 0.9);
}

.delete-schedules .modal-content {
  background: #fff;
  box-shadow: 5px 5px 5px #000;
  border-radius: 5px;
  padding: 10px;
}

.deleteConfirmbox-actions {
  float: right;
}

.deleteConfirmbox {
  line-height: 30px;
  font-size: 16px;
}

h1.pagination {
  text-align: center;
  position: relative;
  right: 32%;
  top: 10px;
  color: #ccc;
}

.subtitle.calendartitle-txt {
  width: 100%;
}

.rbc-calendar .prev .btn,
.rbc-calendar .next .btn {
  background: #333;
}

.rbc-calendar .prev i,
.rbc-calendar .next i {
  color: #ccc;
  font-weight: 100;
}

.calendar-popup .box {
  background: transparent !important;
}

.foreverValidationText {
  line-height: 22px;
  padding: 5px;
}

.foreverValidationText button {
  color: #fff;
  background-color: #f74866;
  border: 0;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  margin: 10px 10px 0 0;
}

.modal-actions {
  float: right;
  margin: 15px 0 5px 0;
}

.rbc-time-view .rbc-time-content {
  display: none;
}

.rbc-time-view .rbc-label.rbc-time-header-gutter {
  display: none;
}

.rbc-time-view .rbc-time-header.rbc-overflowing {
  border: 1px solid #333;
  padding: 10px;
}

.rbc-time-view .rbc-day-bg {
  border: 0 !important;
}

.rbc-overlay {
  position: absolute;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 5px 0 5px 5px;
  border: 0;
  background-color: #222;
  max-height: 160px;
  overflow-y: auto;
  z-index: 999;
}

.calendardiv .pagination {
    margin: 5px auto;
}
.previewTypeImage {
  height: 355px;
  width: 165px;
  margin-left: 10px;
  float: left;
}

.previewContent {
  margin: 10px 0 0 15px;
  height: 235px;
  width: 460px;
  overflow: hidden;
  float: left;
}

.previewSupport {
  padding: 10px;
  text-align: left;
  margin: 5px 0 0 0;
  line-height: 18px;
  color: #000;
}

.previewSupport svg {
  font-size: 24px;
  font-weight: bold;
  color: #ff6600;
  margin: 4px 10px 0 0;
  float: left;
}

/* playlist preview type - previeweview2 */
.previeweview2 .smoking {
  margin-top: 10px;
}

.previeweview2 .uparrow img {
  margin: 40px 68px 0;
  max-width: 30px;
  text-align: center;
}

.previeweview2 .pinumber {
  font-size: 140px;
  color: #fff;
  text-align: center;
  line-height: 110px;
  margin-bottom: 30px;
  font-family: "PragatiNarrow", sans-serif;
}

.previeweview2 .nextstoptxt {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "LatoRegular", sans-serif;
}

.previeweview2 .nextstopnumber {
  font-size: 16px;
  color: #fff;
  float: right;
  font-family: "LatoRegular", sans-serif;
}

.previeweview2 .datetime {
  text-align: center;
  color: #fff;
}

.previeweview2 .date {
  font-size: 37px;
  margin-top: 10px;
  font-family: "LatoRegular", sans-serif;
}

.previeweview2 .time {
  font-size: 20px;
  margin-top: 5px;
  font-family: "LatoRegular", sans-serif;
}

/* playlist preview type - previewinfotainment */
.previewinfotainment {
  color: #fff;
  text-align: center;
}

.previewinfotainment .date {
  font-size: 37px;
  margin-top: 10px;
  font-family: "LatoRegular", sans-serif;
}

.previewinfotainment .time {
  font-size: 20px;
  margin-top: 5px;
  font-family: "LatoRegular", sans-serif;
}

.previewinfotainment .infomsg {
  font-size: 20px;
  margin: 100px 0 30px;
  font-family: "LatoRegular", sans-serif;
}

.previewinfotainment .smoking {
  margin-bottom: 80px;
}

.previewinfotainment .smoking img {
  width: 35px;
  height: 33px;
}

.previewinfotainment .smoking .logo {
  width: 70px;
}

/* playlist preview type - previewinfotainment */
.previewnaa {
  text-align: center;
  color: #fff;
}

.previewnaa .uparrow {
  margin-top: 100px;
}

.previewnaa .uparrow img {
  width: 50px;
}

.previewnaa .pinumber {
  font-size: 110px;
  color: #fff;
  text-align: center;
  line-height: 110px;
  margin-bottom: 30px;
  font-family: "PragatiNarrow", sans-serif;
}

.previewnaa .datetime {
  margin-top: 100px;
}

.previewnaa .date {
  font-size: 24px;
  margin-top: 10px;
  font-family: "LatoRegular", sans-serif;
}

.previewnaa .time {
  font-size: 24px;
  font-family: "LatoRegular", sans-serif;
}

.leftpierrormsg {
  width: 125px;
  font-weight: 100;
  font-family: 'LatoRegular', sans-serif;
  font-size: 18px;
  color: #ff9900;
  position: relative;
  margin: auto;
  vertical-align: middle;
  height: 100%;
  top: 42%;
  text-align: center;
}

.pierrormsg {
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  vertical-align: middle;
  margin: 10px;
  height: 95%;
}
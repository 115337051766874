.footer {
	position: fixed;
	bottom: 0;
	background: #1f1f1f;
	display: table;
	width: calc(100% - 80px);
	margin: 0 0 0 65px;
	border-bottom: 10px solid #000;
	border-top: 0;
	height: 40px;
	z-index: 999;
}

.footer ul {
	margin: 7px 0 0 10px;
	padding: 0;
}

.footer li {
	float: left;
	color: #999;
	list-style: none;
	margin: 0 25px 0 0;
	font-size: 13px;
}

.footer li.version {
	float: right;
	color: #666;
	font-size: 13px;
	display: block;
	padding: 0 10px 0 0;
	margin: 0;
}
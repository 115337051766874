.password-reset ul {
  -webkit-padding-start: 0;
  padding: 0;
  }
  
  .password-reset li {
  list-style-type: none;
  }
  
  .password-reset  {
  width: 348px;
  margin-left: 40px;
  margin-top: 40px;
  position: relative;
  height: 470px;
  }
  
  .password-reset .intro {
  color: #666;
  line-height: 1.5;
  }
  
  .password-reset .helpLink {
  width: auto;
  position: relative;
  float: right;
  margin: 10px 0 0 0;
  } 
  
  .password-reset #email {
  margin-bottom: 8px;
  margin-top: 10px;
  width: 348px;
  }
  
  .password-reset label#email_ver_input_label {
  display: none !important;
  }
  
  .password-reset .buttons.verify {
  width: 300px;
  }
  
  .password-reset .verifyInput {
  margin-top: 8px;
  width: 244px;
  }
  
  .password-reset h1 {
    font-size: 27px;
    font-weight: 400;
    padding-bottom: 25px;
    padding-top: 70px;
}

  
  .password-reset .copy {
  color: #666;
  line-height: 1.5;
  margin-bottom: 42px;
  }
  
  .password-reset .entry-item {
  margin-top: 16px;
  }
  
  .password-reset .verificationErrorText.error,
  .password-reset .error p {
  color: #d63a5b;
  }
  
  .password-reset a {
  border-bottom: 1px solid #d63a5b;
  color: #d63a5b;
  text-decoration: none;
  padding-bottom: 1px;
  }
  
  .password-reset input {
  font-family: inherit;
  background-color: #000;
  color: #fff;
  border: none;
  border-bottom: 1px solid #999;
  background: transparent;
  width: 100%;
  padding-bottom: 3px;
  margin-top: 8px;
  box-shadow: none;
  }
  
  .password-reset input:focus {
  outline: none;
  }
  
  .password-reset input:placeholder {
  color: #999;
  }
  
  .password-reset button,
  .password-reset select {
  background: #ccc;
  color: #000;
  }
  
  .password-reset button,
  .password-reset select {
  
  color: #fff;
  }
  
  .password-reset button {
  background-color: #d63a5b;;
  border: 1px solid #d63a5b;
  cursor: pointer;
  padding: 8px 10px;
  margin-top: 24px;
  margin-right: 20px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  min-width: 90px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  }
  
  .password-reset button:hover {
  color: #fff;
  background-color: #ff6f88;
  border: 1px solid #ff6f88;
  transition: background-color 200ms ease all;
  -webkit-transition: background-color 200ms ease;
  }
  .password-reset #continue{
  float:left !important;
  }
  
  .password-reset #cancel {
  float:left !important;
  border: 1px solid #ccc;
  background: #ccc;
  color: #000;
  }
  
  .password-reset #cancel:hover {
  color: #ccc;
  background-color: #000;
  transition: background-color 200ms ease all;
  -webkit-transition: background-color 200ms ease;
  }
  
  .password-reset label {
  display: none;
  }
  
  .password-reset input:-webkit-autofill,
  .password-reset input:-webkit-autofill:hover,
  .password-reset input:-webkit-autofill:focus {
  background-color: #000 !important;
  -webkit-box-shadow: 0 0 0 30px black inset;
  box-shadow: 0 0 0 30px black inset;
  -webkit-text-fill-color: #fff;
  }
  
  .password-reset #email_ver_but_send {
  margin: 40px 0 0 0;
  }
  .password-reset .top-heading {
  color: #fff;
  font-size: 1.8rem;
  margin-top: 12px;
  margin-bottom: -43px;
  font-weight: 300;
  width:390px;
  }
  
  .password-reset #no_cookie{ display:none; }
  
  .password-reset #password_req {
  margin: 15px 0 0 0;
  }   
  .password-reset #password_req ul {
  margin: 5px 0 0 0;
  }
  .password-reset #password_req ul li {
  padding: 0 0 3px 10px;
  }
  .password-reset #cnpassword {
  margin: 15px 0 0 0;
  }   
  .password-reset #cnpassword ul {
  margin: 5px 0 0 0;
  }
  .password-reset #cnpassword ul li {
  padding: 0 0 3px 10px;
  }
  .password-reset .error.itemLevel p {     
  padding-top: 15px;
  }
  .email-notif .modal-content {
    border-radius: 5px;
    padding: 15px;
    font-size: 14px;
}

.email-notif.fade.modal.show.email-notif {
    top: 0 !important;
}

.email-notif .modal-dialog {
    margin-top: 0;
    width: 40%;
}

.email-notif .modal-content button {
    background: #1a73e8;
    border: 0;
    color: #fff;
    padding: 6px 15px;
    border-radius: 3px;
    float: right;
    font-size: 12px;
    font-weight: 700;
}

.password-reset button:disabled,
.password-reset button[disabled]{
  border: 1px solid #444;
  background-color: #444;
  color: #777;
}

.password-reset button:disabled:Hover,
.password-reset button[disabled]:Hover{
  background-color: #333;
    border: 1px solid #333;
    color: #666;
	cursor: no-drop;
}
.unitimage {
	width: auto;
	float: left;
	margin: 2px 10px 4px 0;
	max-width: 100px;
}
.unitimage img {
	width: 100%;
}
.groupselect {
  position: absolute;
  z-index: 12;
  top: 7px;
  left: 8px;
}
.unitDetails {
	margin: 5px 0;
	background: #282828;
	padding: 10px;
	display: table;
	width: 100%;
}

.unitselect {
	float: left;
  height: 100px;
	width: 20px;
	margin: 0;
	padding: 0;
}
.cpid {
	padding: 7px 0 10px 10px;
	color: #999;
	font-size: 13px;

}
.deviceType {
	padding: 0 0 10px 0;
	font-size: 13px;
	color: #999;

}

.selectedDevicesInfo {
  border-top: 2px solid #e0e0e0;
  color: #999;
  margin-top: 20px;
  text-align: center;
}
.selectedDevicesInfo div {
  display: inline-block;
}
.selectedDevicesInfo p {
  margin-top: 20px;
  color: #666;
  font-weight: 100;
  font-size: 13px;
}
.selectedDevicesInfo button {
  cursor: pointer;
    position: relative;
    opacity: .7;
    display: inline-block;
    padding: 2px 32px 2px 10px;
    border: 1px solid #cadae6;
    border-radius: 20px;
    vertical-align: middle;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 10px;
    color: #333;
    line-height: 24px;
    background: url(../../Assets/close-icon.png) right 10px center no-repeat !important;
    background-size: 18px !important;
    background-color: #ccc !important;
    font-weight: 100;
}

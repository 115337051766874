.improvedselect ul li {
  padding: 0 5px;
  display: block;
  margin: 0;
  cursor: pointer;
  background: none;
}

.improvedselect .del_all:hover,
.improvedselect .add_all:hover,
.improvedselect .del:hover,
.improvedselect .add:hover {
  background: #ccc;
}

.improvedselect .improvedselect-text-wrapper {
  border: none;
  margin-bottom: 5px;
}

.improvedselect input.improvedselect_filter {
  width: 100%;
  height: 20px;
  padding: 0 2%;
}

.improvedselect .add_all,
.improvedselect .del_all,
.improvedselect .del,
.improvedselect .add {
  font-size: 20px;
  width: 50px !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.improvedselect .improvedselect_control {
  float: left;
  width: 18%;
  padding-top: 30px;
}

.improvedselect li:hover {
  background: #444;
}

.improvedselect li.selected {
  color: white;
  background: #444;
}

.improvedselect ul.improvedselect_sel,
.improvedselect ul.improvedselect_all {
  width: 40%;
  height: 200px;
  margin: 0;
  padding: 0;
  display: block;
  list-style: none;
  overflow: auto;
  background: #222;
  border: 0;
}

.improvedselect .improvedselect_all {
  float: left;
}

.improvedselect .improvedselect_sel {
  float: right;
}

.improvedselect .clear {
  clear: both;
}

.improvedselect {
  border: none;
  background: #333;
  padding: 1%;
  min-width: 440px;
}